// const strategyContractAddress = "0x438f48F9dc8D371497cFDFc39af296D8A2F370B6"
const listOfActiveContract = [ '0xBBb6aD1765216D907dD704c775816bE365C01e21', '0x30ef8344B72A2e75E03d4aC6D934C92A29177a7f'];
const whitelistedBNBFactoryAddress = "0x56A8509Ac25bB288991b0430e6408DAc14672168"
const whitelistedMantle2FactoryAddress = "0x55b0f90d25f06F12FfA9C573adE14221b6405427" //--
const FACTORY_CONTRACT_DEPLOYMENT_BLOCK = 12086614
const RPCUrl = 'https://testnet.rivera.money:8546/'
const mantleRPCUrl = 'https://rpc.testnet.mantle.xyz'
const backendApiUrlBase = 'http://54.83.96.50/'
const testnetRpc = "https://node.rivera.money/"
const riveraAdminPrivateKey = "0xfc2f8cc0abd2d9d05229c8942e8a529d1ba9265eb1b4c720c03f7d074615afbb"
const CLAIM_CONTRACT_ADDRESS = "0x0d7928359e8eD07f3F5DdE01224A313bA4112e16";


export { whitelistedBNBFactoryAddress,whitelistedMantle2FactoryAddress, FACTORY_CONTRACT_DEPLOYMENT_BLOCK, RPCUrl, mantleRPCUrl, listOfActiveContract, backendApiUrlBase, testnetRpc, riveraAdminPrivateKey, CLAIM_CONTRACT_ADDRESS }