import "primereact/resources/themes/lara-light-indigo/theme.css";   
import "primereact/resources/primereact.min.css";  
import '@rainbow-me/rainbowkit/styles.css'
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { WagmiConfig } from 'wagmi';
import { RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { chains, client } from './wagmi';
import { Route, RouterProvider, createHashRouter, createRoutesFromElements } from "react-router-dom";
import RoutingLayout from "./RoutingLayout";
import Home from "./Home";
import VaultDetails from "./VaultDetails";
import VaultRange from "./VaultRange";
import VaultManagerDetails from "./VaultManagerDetails";
import Discover from "./Discover";
import DexDetails from "./DexDetails";
import VaultList from "./VaultList";
import Quant from "./Quant";
import Claim from './Claim'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<RoutingLayout />}>
      <Route index element={<Home />} />
      <Route path="dashboard" element={<Home />} />
      <Route path="quant" element={<Quant />} />
      <Route path="discover" element={<Discover />} />
      <Route path="faucet" element={<Claim />} />
      <Route path="discover/:exchangeName" element={<DexDetails />} >
          <Route path=":chainName" element={<VaultList />} />
      </Route>
      <Route path="discover/:exchangeName/:chainName/:vaultAddress" element={<VaultDetails />} />
      <Route path="vaultManager/:vaultAddress" element={<VaultManagerDetails />} />
      <Route path="valutRangeSelector/:vaultAddress" element={<VaultRange />} />
      <Route path="vault/:vaultAddress" element={<VaultDetails />} />
    </Route>
  )
);

root.render(
  // <React.StrictMode>
     <WagmiConfig client={client}>
      <RainbowKitProvider chains={chains} theme={lightTheme({
      accentColor: '#7b3fe4',
      accentColorForeground: 'white',
      borderRadius: 'medium',
      fontStack: 'system',
      overlayBlur: 'small',
    })}>
        <RouterProvider router={router} />
      </RainbowKitProvider>
    </WagmiConfig>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
