import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Menubar } from "primereact/menubar";
import { Outlet, useNavigate } from "react-router-dom";
import logoImg from "./assets/images/logo.png";
import mobileLogoImg from "./assets/images/logoHeader.svg";
import { useAccount, useSigner } from "wagmi";
import { ReactComponent as Sun } from "./assets/images/Sun.svg";
import { ReactComponent as Moon } from "./assets/images/Moon.svg";
import ConnectBtn from "./ConnectBtn";
import waitingImg from "./assets/images/waiting.gif";
import successfullImg from "./assets/images/successfull.gif";
import { Toast } from "primereact/toast";

function RoutingLayout() {
  const [approvalWaitingVisible, setApprovalWaitingVisible] = useState(false);
  const [isClaimDone, setIsClaimDone] = useState(false);
  const [checkBoxVal, setCheckBoxVal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(
    localStorage.getItem("selectedTheme") || "dark"
  );
  const { address } = useAccount();
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();

  const { data: signer, isError, isLoading } = useSigner();

  const toggleTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckBoxVal(e.target.checked);
    if (e.target.checked) {
      setDarkMode();
    } else {
      setLightMode();
    }
  };

  useEffect(() => {
    const selectedTheme = localStorage.getItem("selectedTheme") || "dark";
    setSelectedTheme(selectedTheme);
    if (selectedTheme === "dark" || !selectedTheme) {
      setDarkMode();
      setCheckBoxVal(true);
    }
  }, []);

  const handleCloseDialog = () => {
    setApprovalWaitingVisible(false);
  };

  const renderDialogContent = () => {
    if (isClaimDone) {
      return (
        <div className="text-center">
          <img src={successfullImg} alt="success" className="gif_wdth" />
          <div className="header_font_size">Successfully Claimed</div>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Claiming Testnet Token...</div>
        </div>
      );
    }
  };

  const items = [
    {
      label: "Dashboard",
      command: () => {
        navigateToPage("/");
      },
    },
    // {
    //     label: 'Claim Testnet Assets',
    //     command: () => { navigateToPage('/faucet') },
    // }
  ];

  const start = (
    <>
      <img
        alt="logo"
        src={logoImg}
        height="40"
        className="mr-2 crsr_pntr notMobileDevice"
        onClick={() => {
          navigateToPage("/");
        }}
      />
      <img
        alt="logo"
        src={mobileLogoImg}
        height="40"
        className="mr-2 crsr_pntr mobileDevice"
        onClick={() => {
          navigateToPage("/");
        }}
      />
    </>
  );

  const end = <ConnectBtn />;
  const toggleButton = (
    <div className="dark_mode">
      <input
        className="dark_mode_input"
        type="checkbox"
        id="darkmode-toggle"
        checked={checkBoxVal}
        onChange={toggleTheme}
      />
      <label className="dark_mode_label" htmlFor="darkmode-toggle">
        <Sun />
        <Moon />
      </label>
    </div>
  );

  const endButton = (
    <div className="d-flex">
      <div className="mr_mob_20">{end}</div>
      <div>{toggleButton}</div>
    </div>
  );

  const navigateToPage = (string: any) => {
    navigate(string);
  };

  const setDarkMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
    setSelectedTheme("dark");
  };

  const setLightMode = () => {
    document.querySelector("body")?.setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
    setSelectedTheme("light");
  };

  return (
    <>
      <div className="card bg_gray">
        <Menubar
          model={items}
          start={start}
          end={endButton}
          className="custom-container"
        />
      </div>
      <div className="main">
        <Outlet />
      </div>

      <Dialog visible={approvalWaitingVisible} onHide={handleCloseDialog}>
        {renderDialogContent()}
      </Dialog>
    </>
  );
}

export default RoutingLayout;
