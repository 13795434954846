import { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { ethers, Wallet } from 'ethers';
import { useAccount } from 'wagmi';
import ConnectBtn from './ConnectBtn';
import waitingImg from './assets/images/waiting.gif';
import successfullImg from './assets/images/successfull.gif';
import { CLAIM_CONTRACT_ADDRESS, riveraAdminPrivateKey, testnetRpc } from './constants/global.js';
import { Toast } from 'primereact/toast';

const Claim: React.FC = () => {
    const [approvalWaitingVisible, setApprovalWaitingVisible] = useState(false);
    const [errorDisplay, setErrorDisplay] = useState(false);
    const [isClaimDone, setIsClaimDone] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { address, isConnected } = useAccount();
    const toast = useRef<Toast>(null);

    const claimABi = [
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_tokenA",
                    "type": "address"
                },
                {
                    "internalType": "address",
                    "name": "_tokenB",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_amountA",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_amountB",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_amountG",
                    "type": "uint256"
                }
            ],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_user",
                    "type": "address"
                }
            ],
            "name": "claim",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "emptyAll",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "tokenA",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "tokenB",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "stateMutability": "payable",
            "type": "receive"
        }
    ];

    const handleClaimClick = async () => {
        try {
            const providerv2 = new ethers.providers.JsonRpcProvider(testnetRpc);
            const wallet = new Wallet(riveraAdminPrivateKey, providerv2);
            const contract = new ethers.Contract(CLAIM_CONTRACT_ADDRESS, claimABi, wallet);
            setIsClaimDone(false);
            setApprovalWaitingVisible(true);
            const transaction = await contract.claim(address, {
                gasLimit: 1000000
            });
            await transaction.wait();
            setIsClaimDone(true);
            console.log("Claim successful!");
        } catch (error) {
            setApprovalWaitingVisible(false);
            setErrorDisplay(true);
            setErrorMessage('Some Error Occured Or Faucet Is Empty!');
            console.error(error);
            if (toast.current) {
                toast.current.show({
                    severity: 'error',
                    detail: 'You have already claimed',
                    life: 6000,
                    style: {
                        display: 'flex',
                        flexDirection: 'row', 
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '300px',
                        height: '100px',
                        backgroundColor: 'rgba(255, 255, 255, 1)',
                        borderRadius: '8px',
                        padding: '16px',
                        position: 'fixed', 
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    },
                });
            }
        }
    };

    const handleCloseDialog = () => {
        setApprovalWaitingVisible(false);
    };

    const renderDialogContent = () => {
        if (isClaimDone) {
            return (
                <div className='text-center'>
                    <img src={successfullImg} alt='success' className='gif_wdth' />
                    <div className='header_font_size'>Successfully Claimed</div>
                </div>
            );
        } else if (approvalWaitingVisible) {
            return (
                <div className='text-center'>
                    <img src={waitingImg} alt='chain' className='gif_wdth' />
                    <div className='header_font_size'>Claiming Testnet Token...</div>
                </div>
            );
        } else if (errorDisplay) {
            return (
                <div className='text-center'>
                    <img src={waitingImg} alt='error' className='gif_wdth' />
                    <div className='header_font_size'>Some Error Occured Or Faucet Is Empty</div>
                </div>
            );
        } else {
            return (
                <div className='text-center'>
                    <img src={waitingImg} alt='chain' className='gif_wdth' />
                    <div className='header_font_size'>Some Error Occurred !!</div>
                </div>
            );
        }
    };

    return (
        <div className="claim">
            {isConnected ? (
                <>
                    <button className="btn btn-riv-primary" onClick={handleClaimClick}>
                        Claim
                    </button>
                    <Toast ref={toast} />
                    <Dialog visible={approvalWaitingVisible} onHide={handleCloseDialog}>
                        {renderDialogContent()}
                    </Dialog>
                </>
            ) : (
                <div className='mb_20'><ConnectBtn /></div>
            )}
        </div>
    );
};

export default Claim;
