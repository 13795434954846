import "./App.css";
import { useNavigate } from "react-router-dom";
import {
  FACTORY_CONTRACT_DEPLOYMENT_BLOCK,
  RPCUrl,
  listOfActiveContract,
  mantleRPCUrl,
} from "./constants/global.js";
import {
  whitelistedBNBFactoryAddress,
  whitelistedMantle2FactoryAddress,
} from "./constants/global.js";
import cashaaImg from "./assets/images/cashaa.png";
import tvlIMg from "./assets/images/tvlv2.svg";
import assetsManagerImg from "./assets/images/assetsManager.png";
import rocketImg from "./assets/images/rocket.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount, useProvider, useSigner } from "wagmi";
import { ProgressBar } from "primereact/progressbar";
import { ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import buttonArrowImg from "./assets/images/button_arrow.png";
import saftyImg from "./assets/images/safty.png";
import keyCircleImg from "./assets/images/keyCircle2.svg";
import seetingCircleImg from "./assets/images/seetingCircle2.svg";
import pancakeWhitelistedVaultFactoryV2Json from "./abi/out/PancakeWhitelistedVaultFactoryV2.sol/PancakeWhitelistedVaultFactoryV2.json";
import RiveraAutoCompoundingVaultV2WhitelistedJson from "./abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import bnbImg from "./assets/images/bnb.png";
import ethImg from "./assets/images/eth.png";
import bitLogoImg from "./assets/images/bitLogo.png";
import StablePairColorImg from "./assets/images/StablePairColor.svg";
import VolatilePairColorImg from "./assets/images/volatilePair.png";
import almImg from "./assets/images/alm.svg";
import LSDFarmingImg from "./assets/images/LSDFarming.svg";
import dynamicStrategyImg from "./assets/images/dynamicStrategy.png";
import { Skeleton } from "primereact/skeleton";
import Decimal from "decimal.js";
import bckgrnd_img_card from "./assets/images/stableFusionx.png";
import ConnectBtn from "./ConnectBtn";

function Home() {
  const stakeContractAbi = [
    { type: "constructor", inputs: [] },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "burn",
      inputs: [
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "amount", internalType: "uint128" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint128", name: "amount0", internalType: "uint128" },
        { type: "uint128", name: "amount1", internalType: "uint128" },
      ],
      name: "collect",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "amount0Requested", internalType: "uint128" },
        { type: "uint128", name: "amount1Requested", internalType: "uint128" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint128", name: "amount0", internalType: "uint128" },
        { type: "uint128", name: "amount1", internalType: "uint128" },
      ],
      name: "collectProtocol",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "uint128", name: "amount0Requested", internalType: "uint128" },
        { type: "uint128", name: "amount1Requested", internalType: "uint128" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "factory",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint24", name: "", internalType: "uint24" }],
      name: "fee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "feeGrowthGlobal0X128",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "feeGrowthGlobal1X128",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "flash",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "increaseObservationCardinalityNext",
      inputs: [
        {
          type: "uint16",
          name: "observationCardinalityNext",
          internalType: "uint16",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "initialize",
      inputs: [
        { type: "uint160", name: "sqrtPriceX96", internalType: "uint160" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint128", name: "", internalType: "uint128" }],
      name: "liquidity",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IFusionXV3LmPool",
        },
      ],
      name: "lmPool",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint128", name: "", internalType: "uint128" }],
      name: "maxLiquidityPerTick",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "mint",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "amount", internalType: "uint128" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint32", name: "blockTimestamp", internalType: "uint32" },
        { type: "int56", name: "tickCumulative", internalType: "int56" },
        {
          type: "uint160",
          name: "secondsPerLiquidityCumulativeX128",
          internalType: "uint160",
        },
        { type: "bool", name: "initialized", internalType: "bool" },
      ],
      name: "observations",
      inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "int56[]", name: "tickCumulatives", internalType: "int56[]" },
        {
          type: "uint160[]",
          name: "secondsPerLiquidityCumulativeX128s",
          internalType: "uint160[]",
        },
      ],
      name: "observe",
      inputs: [
        { type: "uint32[]", name: "secondsAgos", internalType: "uint32[]" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        {
          type: "uint256",
          name: "feeGrowthInside0LastX128",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "feeGrowthInside1LastX128",
          internalType: "uint256",
        },
        { type: "uint128", name: "tokensOwed0", internalType: "uint128" },
        { type: "uint128", name: "tokensOwed1", internalType: "uint128" },
      ],
      name: "positions",
      inputs: [{ type: "bytes32", name: "", internalType: "bytes32" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "token0", internalType: "uint128" },
        { type: "uint128", name: "token1", internalType: "uint128" },
      ],
      name: "protocolFees",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setFeeProtocol",
      inputs: [
        { type: "uint32", name: "feeProtocol0", internalType: "uint32" },
        { type: "uint32", name: "feeProtocol1", internalType: "uint32" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setLmPool",
      inputs: [{ type: "address", name: "_lmPool", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint160", name: "sqrtPriceX96", internalType: "uint160" },
        { type: "int24", name: "tick", internalType: "int24" },
        { type: "uint16", name: "observationIndex", internalType: "uint16" },
        {
          type: "uint16",
          name: "observationCardinality",
          internalType: "uint16",
        },
        {
          type: "uint16",
          name: "observationCardinalityNext",
          internalType: "uint16",
        },
        { type: "uint32", name: "feeProtocol", internalType: "uint32" },
        { type: "bool", name: "unlocked", internalType: "bool" },
      ],
      name: "slot0",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "int56", name: "tickCumulativeInside", internalType: "int56" },
        {
          type: "uint160",
          name: "secondsPerLiquidityInsideX128",
          internalType: "uint160",
        },
        { type: "uint32", name: "secondsInside", internalType: "uint32" },
      ],
      name: "snapshotCumulativesInside",
      inputs: [
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "int256", name: "amount0", internalType: "int256" },
        { type: "int256", name: "amount1", internalType: "int256" },
      ],
      name: "swap",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "bool", name: "zeroForOne", internalType: "bool" },
        { type: "int256", name: "amountSpecified", internalType: "int256" },
        { type: "uint160", name: "sqrtPriceLimitX96", internalType: "uint160" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tickBitmap",
      inputs: [{ type: "int16", name: "", internalType: "int16" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "int24", name: "", internalType: "int24" }],
      name: "tickSpacing",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "liquidityGross", internalType: "uint128" },
        { type: "int128", name: "liquidityNet", internalType: "int128" },
        {
          type: "uint256",
          name: "feeGrowthOutside0X128",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "feeGrowthOutside1X128",
          internalType: "uint256",
        },
        { type: "int56", name: "tickCumulativeOutside", internalType: "int56" },
        {
          type: "uint160",
          name: "secondsPerLiquidityOutsideX128",
          internalType: "uint160",
        },
        { type: "uint32", name: "secondsOutside", internalType: "uint32" },
        { type: "bool", name: "initialized", internalType: "bool" },
      ],
      name: "ticks",
      inputs: [{ type: "int24", name: "", internalType: "int24" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "token0",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "token1",
      inputs: [],
    },
    {
      type: "event",
      name: "Burn",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "int24", name: "tickLower", indexed: true },
        { type: "int24", name: "tickUpper", indexed: true },
        { type: "uint128", name: "amount", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Collect",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "address", name: "recipient", indexed: false },
        { type: "int24", name: "tickLower", indexed: true },
        { type: "int24", name: "tickUpper", indexed: true },
        { type: "uint128", name: "amount0", indexed: false },
        { type: "uint128", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "CollectProtocol",
      inputs: [
        { type: "address", name: "sender", indexed: true },
        { type: "address", name: "recipient", indexed: true },
        { type: "uint128", name: "amount0", indexed: false },
        { type: "uint128", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Flash",
      inputs: [
        { type: "address", name: "sender", indexed: true },
        { type: "address", name: "recipient", indexed: true },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
        { type: "uint256", name: "paid0", indexed: false },
        { type: "uint256", name: "paid1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "IncreaseObservationCardinalityNext",
      inputs: [
        {
          type: "uint16",
          name: "observationCardinalityNextOld",
          indexed: false,
        },
        {
          type: "uint16",
          name: "observationCardinalityNextNew",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Initialize",
      inputs: [
        { type: "uint160", name: "sqrtPriceX96", indexed: false },
        { type: "int24", name: "tick", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Mint",
      inputs: [
        { type: "address", name: "sender", indexed: false },
        { type: "address", name: "owner", indexed: true },
        { type: "int24", name: "tickLower", indexed: true },
        { type: "int24", name: "tickUpper", indexed: true },
        { type: "uint128", name: "amount", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "SetFeeProtocol",
      inputs: [
        { type: "uint32", name: "feeProtocol0Old", indexed: false },
        { type: "uint32", name: "feeProtocol1Old", indexed: false },
        { type: "uint32", name: "feeProtocol0New", indexed: false },
        { type: "uint32", name: "feeProtocol1New", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "SetLmPoolEvent",
      inputs: [{ type: "address", name: "addr", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "Swap",
      inputs: [
        { type: "address", name: "sender", indexed: true },
        { type: "address", name: "recipient", indexed: true },
        { type: "int256", name: "amount0", indexed: false },
        { type: "int256", name: "amount1", indexed: false },
        { type: "uint160", name: "sqrtPriceX96", indexed: false },
        { type: "uint128", name: "liquidity", indexed: false },
        { type: "int24", name: "tick", indexed: false },
        { type: "uint128", name: "protocolFeesToken0", indexed: false },
        { type: "uint128", name: "protocolFeesToken1", indexed: false },
      ],
      anonymous: false,
    },
  ];
  const nonfungibleContractAbi = [
    {
      type: "constructor",
      inputs: [
        { type: "address", name: "_deployer", internalType: "address" },
        { type: "address", name: "_factory", internalType: "address" },
        { type: "address", name: "_WETH9", internalType: "address" },
        { type: "address", name: "_tokenDescriptor_", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bytes32", name: "", internalType: "bytes32" }],
      name: "DOMAIN_SEPARATOR",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bytes32", name: "", internalType: "bytes32" }],
      name: "PERMIT_TYPEHASH",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "WETH9",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "approve",
      inputs: [
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "balanceOf",
      inputs: [{ type: "address", name: "owner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "pure",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "baseURI",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "burn",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "collect",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType: "struct INonfungiblePositionManager.CollectParams",
          components: [
            { type: "uint256" },
            { type: "address" },
            { type: "uint128" },
            { type: "uint128" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [{ type: "address", name: "pool", internalType: "address" }],
      name: "createAndInitializePoolIfNecessary",
      inputs: [
        { type: "address", name: "token0", internalType: "address" },
        { type: "address", name: "token1", internalType: "address" },
        { type: "uint24", name: "fee", internalType: "uint24" },
        { type: "uint160", name: "sqrtPriceX96", internalType: "uint160" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "decreaseLiquidity",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType:
            "struct INonfungiblePositionManager.DecreaseLiquidityParams",
          components: [
            { type: "uint256" },
            { type: "uint128" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "deployer",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "factory",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "fusionXV3MintCallback",
      inputs: [
        { type: "uint256", name: "amount0Owed", internalType: "uint256" },
        { type: "uint256", name: "amount1Owed", internalType: "uint256" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "getApproved",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "increaseLiquidity",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType:
            "struct INonfungiblePositionManager.IncreaseLiquidityParams",
          components: [
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "isApprovedForAll",
      inputs: [
        { type: "address", name: "owner", internalType: "address" },
        { type: "address", name: "operator", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint256", name: "tokenId", internalType: "uint256" },
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "mint",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType: "struct INonfungiblePositionManager.MintParams",
          components: [
            { type: "address" },
            { type: "address" },
            { type: "uint24" },
            { type: "int24" },
            { type: "int24" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "address" },
            { type: "uint256" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [{ type: "bytes[]", name: "results", internalType: "bytes[]" }],
      name: "multicall",
      inputs: [{ type: "bytes[]", name: "data", internalType: "bytes[]" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "name",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "ownerOf",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "permit",
      inputs: [
        { type: "address", name: "spender", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
        { type: "uint256", name: "deadline", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint96", name: "nonce", internalType: "uint96" },
        { type: "address", name: "operator", internalType: "address" },
        { type: "address", name: "token0", internalType: "address" },
        { type: "address", name: "token1", internalType: "address" },
        { type: "uint24", name: "fee", internalType: "uint24" },
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        {
          type: "uint256",
          name: "feeGrowthInside0LastX128",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "feeGrowthInside1LastX128",
          internalType: "uint256",
        },
        { type: "uint128", name: "tokensOwed0", internalType: "uint128" },
        { type: "uint128", name: "tokensOwed1", internalType: "uint128" },
      ],
      name: "positions",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "refundETH",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "safeTransferFrom",
      inputs: [
        { type: "address", name: "from", internalType: "address" },
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "safeTransferFrom",
      inputs: [
        { type: "address", name: "from", internalType: "address" },
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
        { type: "bytes", name: "_data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermit",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "value", internalType: "uint256" },
        { type: "uint256", name: "deadline", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermitAllowed",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "nonce", internalType: "uint256" },
        { type: "uint256", name: "expiry", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermitAllowedIfNecessary",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "nonce", internalType: "uint256" },
        { type: "uint256", name: "expiry", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermitIfNecessary",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "value", internalType: "uint256" },
        { type: "uint256", name: "deadline", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setApprovalForAll",
      inputs: [
        { type: "address", name: "operator", internalType: "address" },
        { type: "bool", name: "approved", internalType: "bool" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "supportsInterface",
      inputs: [{ type: "bytes4", name: "interfaceId", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "sweepToken",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "amountMinimum", internalType: "uint256" },
        { type: "address", name: "recipient", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "symbol",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tokenByIndex",
      inputs: [{ type: "uint256", name: "index", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tokenOfOwnerByIndex",
      inputs: [
        { type: "address", name: "owner", internalType: "address" },
        { type: "uint256", name: "index", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "tokenURI",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalSupply",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "transferFrom",
      inputs: [
        { type: "address", name: "from", internalType: "address" },
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "unwrapWETH9",
      inputs: [
        { type: "uint256", name: "amountMinimum", internalType: "uint256" },
        { type: "address", name: "recipient", internalType: "address" },
      ],
    },
    {
      type: "event",
      name: "Approval",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "address", name: "approved", indexed: true },
        { type: "uint256", name: "tokenId", indexed: true },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "ApprovalForAll",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "address", name: "operator", indexed: true },
        { type: "bool", name: "approved", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Collect",
      inputs: [
        { type: "uint256", name: "tokenId", indexed: true },
        { type: "address", name: "recipient", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "DecreaseLiquidity",
      inputs: [
        { type: "uint256", name: "tokenId", indexed: true },
        { type: "uint128", name: "liquidity", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "IncreaseLiquidity",
      inputs: [
        { type: "uint256", name: "tokenId", indexed: true },
        { type: "uint128", name: "liquidity", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Transfer",
      inputs: [
        { type: "address", name: "from", indexed: true },
        { type: "address", name: "to", indexed: true },
        { type: "uint256", name: "tokenId", indexed: true },
      ],
      anonymous: false,
    },
    { type: "receive" },
  ];
  const strategyContractAbi = [
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "NonfungiblePositionManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "acceptManagership",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "acceptOwnership",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "assettoNativeFeed",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "balanceOf",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "changeRange",
      inputs: [
        { type: "int24", name: "_tickLower", internalType: "int24" },
        { type: "int24", name: "_tickUpper", internalType: "int24" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "deposit",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "depositToken",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "feeDecimals",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "fullMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "fundManagerFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "harvest",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "inCaseTokensGetStuck",
      inputs: [{ type: "address", name: "_token", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "init",
      inputs: [
        {
          type: "tuple",
          name: "_riveraLpStakingParams",
          internalType: "struct RiveraLpStakingParams",
          components: [
            { type: "int24" },
            { type: "int24" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
          ],
        },
        {
          type: "tuple",
          name: "_commonAddresses",
          internalType: "struct CommonAddresses",
          components: [
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "lastHarvest",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "liquidityAmountsLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "liquidity", internalType: "uint128" },
      ],
      name: "liquidityBalance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "liquidityMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "lpFeesDepositToken",
          internalType: "uint256",
        },
      ],
      name: "lpRewardsAvailable",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "lpToken0",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "lpToken1",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "manager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
      name: "onERC721Received",
      inputs: [
        { type: "address", name: "", internalType: "address" },
        { type: "address", name: "", internalType: "address" },
        { type: "uint256", name: "", internalType: "uint256" },
        { type: "bytes", name: "", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [],
      name: "onlyManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [],
      name: "onlyVault",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "owner",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "panic",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "partner",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "partnerFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "pause",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "paused",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "pendingOwner",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint24", name: "", internalType: "uint24" }],
      name: "poolFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "protocolFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "renounceOwnership",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "retireStrat",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "router",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "safeCastLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setAssettoNativeFeed",
      inputs: [
        {
          type: "address",
          name: "assettoNativeFeed_",
          internalType: "address",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setManager",
      inputs: [{ type: "address", name: "_manager", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "sqrtPriceMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "stake",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "int24", name: "", internalType: "int24" }],
      name: "tickLower",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "tickMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "int24", name: "", internalType: "int24" }],
      name: "tickUpper",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tokenID",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "transferOwnership",
      inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "unpause",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "vault",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "withdraw",
      inputs: [{ type: "uint256", name: "_amount", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "withdrawFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "withdrawFeeDecimals",
      inputs: [],
    },
    {
      type: "event",
      name: "AssetToNativeFeedChange",
      inputs: [
        { type: "address", name: "oldFeed", indexed: false },
        { type: "address", name: "newFeed", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Deposit",
      inputs: [
        { type: "uint256", name: "tvl", indexed: false },
        { type: "uint256", name: "amount", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Initialized",
      inputs: [{ type: "uint8", name: "version", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "OwnershipTransferStarted",
      inputs: [
        { type: "address", name: "previousOwner", indexed: true },
        { type: "address", name: "newOwner", indexed: true },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "OwnershipTransferred",
      inputs: [
        { type: "address", name: "previousOwner", indexed: true },
        { type: "address", name: "newOwner", indexed: true },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Paused",
      inputs: [{ type: "address", name: "account", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "RangeChange",
      inputs: [
        { type: "int24", name: "tickLower", indexed: false },
        { type: "int24", name: "tickUpper", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "SetManager",
      inputs: [{ type: "address", name: "manager", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "StratHarvest",
      inputs: [
        { type: "address", name: "harvester", indexed: true },
        { type: "uint256", name: "tvl", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Unpaused",
      inputs: [{ type: "address", name: "account", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "Withdraw",
      inputs: [
        { type: "uint256", name: "tvl", indexed: false },
        { type: "uint256", name: "amount", indexed: false },
      ],
      anonymous: false,
    },
  ];
  const viewDetailsbuttonVal = "View Details --->";
  const managebuttonVal = "Manage --->";
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  const [isWhiteListed, setWhiteListed] = useState(true);
  const [totalTvl, setTotalTvl] = useState("");
  const [totalVault, setTotalVault] = useState(0);
  const [portfolio, setPortfolio] = useState("0");
  const [overallReturn, setOverallReturn] = useState("");
  const [totalAverageApy, settotalAverageApy] = useState("");
  const [bnbPriceInUsd, setBnbPriceInUsd] = useState(2);
  const [erc20Abi, seterc20Abi] = useState({});
  const [valutList, setvalutList] = useState<any>([]);
  const { address, isConnected } = useAccount();
  const provider = useProvider();
  const { data: signer, isError, isLoading } = useSigner();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  const [lamaTotalTvl, setLamaTotalTvl] = useState("");

  useEffect(() => {
    // document.querySelector("body")?.setAttribute("data-theme", "darkk");

    getDeployedValut();
  }, [address, isLoading]);

  const goTovaultDeatils = (path: string) => {
    navigate("vault/" + path);
  };

  const getTwoDecimal = (num: any) => {
    // const num = 199.9989;
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  };

  const getActualvalue = (num: any, decimal: any) => {
    // const num = 199.9989;
    const roundedNum = Number(
      new Decimal(num.toString()).div(
        new Decimal(Math.pow(10, Number(decimal)))
      )
    );
    return roundedNum;
  };

  const getContract = (address: string, abi: any, provider: any) => {
    return new ethers.Contract(address, abi, provider);
  };

  async function getPriceInUsd(address: string): Promise<any> {
    if (
      address === "0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111" ||
      address === "0x0Dc808adcE2099A9F62AA87D9670745AbA741746" ||
      address === "0x2FE3AD97a60EB7c79A976FC18Bb5fFD07Dd94BA5"
    ) {
      const data = await fetch(
        "https://api.geckoterminal.com/api/v2/networks/mantle/pools/0xA125AF1A4704044501Fe12Ca9567eF1550E430e8"
      );
      const jsonData = await data.json();
      const price = jsonData["data"]["attributes"].base_token_price_usd;
      return new Promise((resolve, reject) => {
        resolve(Number(price));
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(Number(1));
      });
    }
  }

  const getValutDetailsByChain2 = async (
    valultList: string[],
    jsonData: any
  ) => {
    const valutListVal = await Promise.all(
      valultList?.map(async (vaultAddress: any) => {
        const response = await fetch("/vaultDetails.json");
        const data = await response.json();
        const valutDetailsInJson = data[vaultAddress as string];

        //const foundElement = jsonData.find((element: any) => element['attributes'].address.toLowerCase() == valutDetailsInJson.pollContract.toLowerCase());

        //const lpApr = 240.9 * (Number(foundElement.attributes.volume_usd.h24)) * Number(valutDetailsInJson.feeTier) / Number(foundElement.attributes.reserve_in_usd);

        const lpApr = 0;

        return {
          feeTier: valutDetailsInJson?.feeTier,
          financeType: valutDetailsInJson?.financeType,
          financeTypeVal: valutDetailsInJson?.financeTypeVal,
          strategyTypeVal: valutDetailsInJson?.strategyTypeVal,
          strategyType: valutDetailsInJson?.strategyType,
          name: valutDetailsInJson?.displayName,
          assetImg: valutDetailsInJson?.assetImg,
          assetImg2: valutDetailsInJson?.assetImg2,
          assetName: valutDetailsInJson?.denominationAsset,
          chainImg: valutDetailsInJson?.chainImg,
          saftyRating: valutDetailsInJson?.risk?.safetyScore,
          tvlInUsd: 0,
          tvl: 0,
          averageApy: lpApr.toFixed(2),
          valutAddress: vaultAddress,
          tvlcapInUsd: 0,
          tvlcap: 0,
          valutApy: 0,
          percentage: 0,
          totalPortfolio: 0,
          totalOverallreturnVal: 0,
          totalAverageApy: 0,
          poweredBy: valutDetailsInJson?.poweredBy,
          lendedBy: valutDetailsInJson?.lendedBy,
          isStablePair: valutDetailsInJson?.isStablePair,
          isLSDFarming: valutDetailsInJson?.isLSDFarming,
          isVolatilePair: valutDetailsInJson?.isVolatilePair,
          isLiquidityMining: valutDetailsInJson?.isLiquidityMining,
          isDynamicStrategy: valutDetailsInJson?.isDynamicStrategy,
          isActiveStrategy: valutDetailsInJson?.isActiveStrategy,
          isPeggedStrategy: valutDetailsInJson?.isPeggedStrategy,
          isStaticRange: valutDetailsInJson?.isStaticRange,
          isWideRange: valutDetailsInJson?.isWideRange,
        };
      })
    );

    return valutListVal;
  };

  const getFirstBlockTimeStamp = async () => {
    const data = await fetch(
      "https://api.covalenthq.com/v1/mantle-mainnet/bulk/transactions/0x52f059A19291775f0BbC91cDca5A6c5dDFBb6ddE/?key=cqt_rQY6pwwVJ8jYkYkDKfW4qQY9PTFH"
    );
    const jsonData = await data.json();
    localStorage.setItem(
      "firstBlockTimeStamp",
      jsonData?.data?.items[0]?.block_signed_at
    );
  };

  const getValutDetailsByChain = async (
    valultList: string[],
    jsonData: any
  ) => {
    const valutListVal = await Promise.all(
      valultList?.map(async (vaultAddress: any) => {
        const response = await fetch("/vaultDetails.json");
        const data = await response.json();
        const valutDetailsInJson = data[vaultAddress as string];

        // const foundElement = jsonData.find((element: any) => element['attributes'].address.toLowerCase() == valutDetailsInJson.pollContract.toLowerCase());

        // const lpApr = 240.9 * (Number(foundElement.attributes.volume_usd.h24)) * Number(valutDetailsInJson.feeTier) / Number(foundElement.attributes.reserve_in_usd);

        const lpApr = 0;

        const provider = new ethers.providers.JsonRpcProvider(
          valutDetailsInJson.rpcUrl
        );

        const vaultContract = getContract(
          valutDetailsInJson.vaultAddress,
          RiveraAutoCompoundingVaultV2WhitelistedJson.abi,
          provider
        );

        const asset = await vaultContract.asset(); //it will return the name of the asset of the valut

        const assetsDecimal = await fetch("/assetDecimal.json");
        const assetsDecimalJson = await assetsDecimal.json();
        const assetsDecimalJsonDecimal = assetsDecimalJson[asset as string];

        let totalAssets = await vaultContract.totalAssets(); //it will return the total assets of the valut
        totalAssets = getActualvalue(
          totalAssets,
          assetsDecimalJsonDecimal.decimal
        );
        const convertedPrice = await getPriceInUsd(asset);
        let tvlCap = await vaultContract.totalTvlCap();
        tvlCap = getActualvalue(tvlCap, assetsDecimalJsonDecimal.decimal);
        const tvlcapInUsd = tvlCap * convertedPrice;
        let tvl = totalAssets;
        const tvlInUsd = tvl * convertedPrice;

        let totalSupply = await vaultContract.totalSupply();
        totalSupply = getActualvalue(
          totalSupply,
          assetsDecimalJsonDecimal.decimal
        );

        let firstBlockTimeStamp: any = "";

        if (localStorage.getItem("firstBlockTimeStamp")) {
          firstBlockTimeStamp = localStorage.getItem("firstBlockTimeStamp");
        } else {
          getFirstBlockTimeStamp();
          firstBlockTimeStamp = localStorage.getItem("firstBlockTimeStamp");
        }

        var d1 = new Date(firstBlockTimeStamp);
        var d2 = new Date();

        var diff = d2.getTime() - d1.getTime();
        var daydiff = diff / (1000 * 60 * 60 * 24);

        const currentShareValurInMnt = new Decimal(totalAssets).div(
          new Decimal(totalSupply)
        );

        //const finalVal2 = ((Number(currentShareValurInMnt) - 1) * (365 / Number(daydiff))) * 100;
        const finalVal2 = (Number(currentShareValurInMnt) - 1) * 100;

        //deposit event call to get alldesopit amout
        const depositFilter = vaultContract.filters.Deposit();
        let totalValutDeposit = 0;
        let totalValutDepositWithTime = 0;
        let totalUserDeposit = 0;
        let totalUserDepositWithTime = 0;

        let totalValutwithdraw = 0;
        let totalValutwithdrawWithTime = 0;
        let totalUserwithdraw = 0;
        let totalUserwithdrawWithTime = 0;

        let totalPortfolio = 0;
        let totalOverallreturnVal = 0;
        let totalAverageApy = 0;
        if (address) {
          if (vaultAddress === "0x52f059A19291775f0BbC91cDca5A6c5dDFBb6ddE") {
            setWhiteListed(true);
          } else {
            setWhiteListed(true);
          }

          let share = await vaultContract.balanceOf(address);
          share = getActualvalue(share, assetsDecimalJsonDecimal.decimal);
          const userShareVal = (totalAssets * share) / totalSupply;
          const userShareInUsd = userShareVal * convertedPrice;
          totalPortfolio = totalPortfolio + userShareInUsd;

          let overallReturn =
            userShareVal + totalUserwithdraw - totalUserDeposit;
          const overallReturnInUsd = overallReturn * convertedPrice;
          totalOverallreturnVal = totalOverallreturnVal + overallReturnInUsd;

          let userApyVal;
          if (totalUserDeposit === 0) {
            userApyVal = 0;
          } else {
            userApyVal =
              (userShareVal - (totalUserDeposit - totalUserwithdraw)) /
              (totalUserDepositWithTime - totalUserwithdrawWithTime);
          }

          totalAverageApy = totalAverageApy + userApyVal;
        } else {
        }

        const valutApyVal = 0;

        const returnVal = {
          //"currentPrice": 0,
          tickLowerPrice: 0,
          tickUpperPrice: 0,
          currentAllocation: 0,
          lastHarvestdate: null,
          feeTier: valutDetailsInJson?.feeTier,
          financeType: valutDetailsInJson?.financeType,
          financeTypeVal: valutDetailsInJson?.financeTypeVal,
          strategyTypeVal: valutDetailsInJson?.strategyTypeVal,
          strategyType: valutDetailsInJson?.strategyType,
          name: valutDetailsInJson?.displayName,
          assetImg: valutDetailsInJson?.assetImg,
          assetImg2: valutDetailsInJson?.assetImg2,
          assetName: valutDetailsInJson?.denominationAsset,
          chainImg: valutDetailsInJson?.chainImg,
          saftyRating: valutDetailsInJson?.risk?.safetyScore,
          tvlInUsd: tvlInUsd,
          tvl: tvl,
          averageApy: lpApr.toFixed(2),
          valutAddress: vaultAddress,
          tvlcapInUsd: tvlcapInUsd.toFixed(2),
          tvlcap: tvlCap,
          valutApy: finalVal2.toFixed(2),
          percentage: ((tvlInUsd / tvlcapInUsd) * 100).toFixed(4),
          totalPortfolio: totalPortfolio,
          totalOverallreturnVal: totalOverallreturnVal,
          totalAverageApy: totalAverageApy,
          poweredBy: valutDetailsInJson?.poweredBy,
          lendedBy: valutDetailsInJson?.lendedBy,
          isStablePair: valutDetailsInJson?.isStablePair,
          isLSDFarming: valutDetailsInJson?.isLSDFarming,
          isVolatilePair: valutDetailsInJson?.isVolatilePair,
          isLiquidityMining: valutDetailsInJson?.isLiquidityMining,
          isDynamicStrategy: valutDetailsInJson?.isDynamicStrategy,
          isActiveStrategy: valutDetailsInJson?.isActiveStrategy,
          isPeggedStrategy: valutDetailsInJson?.isPeggedStrategy,
          isStaticRange: valutDetailsInJson?.isStaticRange,
          isWideRange: valutDetailsInJson?.isWideRange,
          currentPrice: 0,
          vaultShare: 0,
        };

        if (
          address &&
          (address.toLocaleLowerCase() ===
            "0x961ef0b358048d6e34bdd1ace00d72b37b9123d7" ||
            address.toLocaleLowerCase() ===
              "0x546734789339b93220df5e0365d7192e182a72a7")
        ) {
          let vaultShare = await vaultContract.convertToAssets(10 ^ 6);
          vaultShare = vaultShare / (10 ^ 6);
          const contract = getContract(
            valutDetailsInJson["strategyAddress"] as string,
            strategyContractAbi,
            signer
          );
          let lastHarvestdate = await contract.lastHarvest();
          lastHarvestdate = new Date(lastHarvestdate * 1000);
          const tokenID = await contract.tokenID();
          const nonfungiblePositionManager =
            await contract.NonfungiblePositionManager();
          const nonfungibleContract = getContract(
            nonfungiblePositionManager as string,
            nonfungibleContractAbi,
            signer
          );
          const nonfungibleDetails = await nonfungibleContract.positions(
            tokenID
          );
          const tickLower = nonfungibleDetails.tickLower;
          const tickUpper = nonfungibleDetails.tickUpper;

          const stakeAddress = await contract.stake();

          //call stake contract
          const stakecontract = getContract(
            stakeAddress as string,
            stakeContractAbi,
            signer
          );
          const stakecontractDetails = await stakecontract.slot0();

          const currentPrice = 1.0001 ^ stakecontractDetails.tick;
          const tickLowerPrice = 1.0001 ^ tickLower;
          const tickUpperPrice = 1.0001 ^ tickUpper;

          const currentAllocation =
            (Math.sqrt(tickUpperPrice / currentPrice) *
              (Math.sqrt(tickUpperPrice) - Math.sqrt(tickLowerPrice))) /
            (Math.sqrt(tickUpperPrice) - Math.sqrt(currentPrice));

          returnVal.currentPrice = currentPrice;
          returnVal.tickLowerPrice = tickLowerPrice;
          returnVal.tickUpperPrice = tickUpperPrice;
          returnVal.currentAllocation = currentAllocation;
          returnVal.lastHarvestdate = lastHarvestdate.toLocaleString();
          returnVal.vaultShare = vaultShare;
        }

        // console.log("returnVal", returnVal);

        return returnVal;
      })
    );

    return valutListVal;
  };

  const checkShowHideLogic = (data: any, vaultAddress: any): boolean => {
    const valutDetailsInJson = data[vaultAddress as string];
    return valutDetailsInJson.show;
  };

  // async function getDeployedValut() {

  //   setIsDataLoadng(true);

  //   //get tvl data
  //   const lamaTvlData = await fetch("https://api.llama.fi/tvl/rivera-money");
  //   const lamaTvlDataVal = await lamaTvlData.json();
  //   setLamaTotalTvl(lamaTvlDataVal);
  //   console.log("lamaTvlDataVal", lamaTvlDataVal);

  //   const data = await fetch(
  //     "https://api.geckoterminal.com/api/v2/networks/mantle/pools/multi/0xA125AF1A4704044501Fe12Ca9567eF1550E430e8%2C0x262255F4770aEbE2D0C8b97a46287dCeCc2a0AfF%2C0xD3d3127D9654f806370da592eb292eA0a347f0e3%2C%2C0x16867D00D45347A2DeD25B8cdB7022b3171D4ae0%2C0x425732f412F2A922156cF3C135a516c18F977Cc1%2C0x1858d52cf57c07A018171D7a1E68DC081F17144f%2C0x54169896d28dec0FFABE3B16f90f71323774949f%2C0xEe12e312878B74b2C17D80516128D7868f80365B"
  //   );
  //   const jsonData = await data.json();

  //   const mantaData = await fetch(
  //     "https://api.geckoterminal.com/api/v2/networks/manta-pacific/pools/multi/0xfee7CAAE3540d7dbE325B34e57E596139cC81f4E%2C0xB1d248BE37C19c3C1539be906c85c617A91323BA%2C0xffEeE0b83fd00032cB035b57a725C9670dfa6F2F%2C0xD7f09148Eb22686Cb5Dcbdd0cF27D04123d14c74"
  //   );
  //   const mantaJsonData = await mantaData.json();

  //   if (
  //     address &&
  //     (address.toLocaleLowerCase() ===
  //       "0x961ef0b358048d6e34bdd1ace00d72b37b9123d7" ||
  //       address.toLocaleLowerCase() ===
  //         "0x546734789339b93220df5e0365d7192e182a72a7")
  //   ) {
  //     setIsAdmin(true);
  //   } else {
  //     setIsAdmin(false);
  //   }

  //   // //fetch list of contarct frmo global js file
  //   // const contractList = listOfActiveContract;
  //   // const contractListpromise = contractList.map(async address =>{
  //   //   //fetch contract details from json
  //   //   const factoryContractDetailJson = await fetch('/factoryContractDetails.json');
  //   //   let factoryContractDetail = await factoryContractDetailJson.json();
  //   //   factoryContractDetail = factoryContractDetail[address];

  //   //   //get provider using contract detail
  //   //   const provider = new ethers.providers.JsonRpcProvider(factoryContractDetail.rpcUrl);

  //   //   //get contact using provder
  //   //   const contract = getContract(address, pancakeWhitelistedVaultFactoryV2Json.abi, provider);

  //   //   //get valut list of contract
  //   //   const valutListVal = await contract.listAllVaults();

  //   //   return valutListVal;

  //   // });

  //   // const contractListValue = await Promise.all(contractListpromise);

  //   // const flatArray: string[] = [].concat(...contractListValue);

  //   // const finalTvlJsonData = [...jsonData['data'], ...mantaJsonData['data']];
  //   const finalTvlJsonData: any = [];

  //   //testnet
  //   const flatArray: string[] = [
  //     "0xf5eB7A02d1B8Dc14D5419Ee9F3f4DeE342960e08_5000",
  //     "0xB78F6BDA3aea432899561cB6FeA63c78ccB9885b_5000",
  //     "0xB813E9C151f2381A52D6B2C7478AB0Dd4E5Afea8_168587773",
  //     "0xA8D248c1257d28D1B4e86AFcA6E2b16aDC729917_93747",
  //   ];
  //   // const flatArray: string[] = ['0xf5eB7A02d1B8Dc14D5419Ee9F3f4DeE342960e08_5000', '0xb642f6F85fc68876700FB2699963611632AD8644_5000','0xB2b593Ab057e99edbAA33258b5613227F64c80C6_5000','0x38B73D78c45b39B7658635fA753EfBE2d4077A33_5000','0x0ca817970d1Bf8789CCB26aC0a6b69d02b6dF34e_5000','0x16d6e3B2979C61D3fa399Cc7D65EFFaadd46682c_5000'];

  //   //mainnet
  //   // const flatArray: string[] = ['0x713C1300f82009162cC908dC9D82304A51F05A3E_169', '0xDc63179CC57783493DD8a4Ffd7367DF489Ae93BF_169', '0x5f247B216E46fD86A09dfAB377d9DBe62E9dECDA_169', '0x0DB2BA00bCcf4F5e20b950bF954CAdF768D158Aa_169'
  //   // , '0x0DB2BA00bCcf4F5e20b950bF954CAdF768D158Aa_5000', '0x713C1300f82009162cC908dC9D82304A51F05A3E_5000',
  //   // '0xDc63179CC57783493DD8a4Ffd7367DF489Ae93BF_5000', '0x5f247B216E46fD86A09dfAB377d9DBe62E9dECDA_5000', '0xfa944c1996efBF9FbFF1a378903F4AD82C172D72_5000', '0x945438ef559EFf400429DFb101e57a6299B5ceE2_5000', '0xA25d1843eedE1E1D0631b979da605606412e64f7_5000',
  //   // '0xAa81F912D09Fd313Bbc1d5638632aB6bf59aB495_5000'];

  //   const valutList = await getValutDetailsByChain2(
  //     flatArray,
  //     finalTvlJsonData
  //   );

  //   const finalList2 = [...valutList];

  //   setvalutList(finalList2);

  //   setLoading(false);

  //   const valutList2 = await getValutDetailsByChain(
  //     flatArray,
  //     finalTvlJsonData
  //   );

  //   const finalList = [...valutList2];
  //   // console.log("finalList", finalList);
  //   setvalutList(finalList);

  //   setTotalVault(flatArray.length);
  //   console.log("setTotalVault", flatArray.length);
  //   const totalOverallreturnVal = finalList.reduce(
  //     (accumulator, currentValue) =>
  //       accumulator + currentValue.totalOverallreturnVal,
  //     0
  //   );
  //   setOverallReturn(totalOverallreturnVal.toFixed(2));

  //   const totalAverageApy = finalList.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue.totalAverageApy,
  //     0
  //   );
  //   settotalAverageApy(totalAverageApy.toFixed(2));

  //   const totalTvlVal = finalList.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue.tvlInUsd,
  //     0
  //   );
  //   setTotalTvl(totalTvlVal.toFixed(2));

  //   const portfolioVal = finalList.reduce(
  //     (accumulator, currentValue) => accumulator + currentValue.totalPortfolio,
  //     0
  //   );
  //   setPortfolio(portfolioVal.toFixed(2));

  //   setIsDataLoadng(false);
  // }
  async function getDeployedValut() {
    setIsDataLoadng(true);

    try {
      // Fetch TVL data from Llama
      const lamaTvlData = await fetch("https://api.llama.fi/tvl/rivera-money");
      if (!lamaTvlData.ok) {
        throw new Error(`Llama TVL API error: ${lamaTvlData.statusText}`);
      }
      const lamaTvlDataVal = await lamaTvlData.json();
      setLamaTotalTvl(lamaTvlDataVal);
      // console.log("lamaTvlDataVal", lamaTvlDataVal);

      // Fetch data from GeckoTerminal Mantle pools
      const data = await fetch(
        "https://api.geckoterminal.com/api/v2/networks/mantle/pools/multi/0xA125AF1A4704044501Fe12Ca9567eF1550E430e8%2C0x262255F4770aEbE2D0C8b97a46287dCeCc2a0AfF%2C0xD3d3127D9654f806370da592eb292eA0a347f0e3%2C%2C0x16867D00D45347A2DeD25B8cdB7022b3171D4ae0%2C0x425732f412F2A922156cF3C135a516c18F977Cc1%2C0x1858d52cf57c07A018171D7a1E68DC081F17144f%2C0x54169896d28dec0FFABE3B16f90f71323774949f%2C0xEe12e312878B74b2C17D80516128D7868f80365B"
      );
      if (!data.ok) {
        throw new Error(`Mantle Pools API error: ${data.statusText}`);
      }
      const jsonData = await data.json();

      // Fetch data from GeckoTerminal Manta Pacific pools
      const mantaData = await fetch(
        "https://api.geckoterminal.com/api/v2/networks/manta-pacific/pools/multi/0xfee7CAAE3540d7dbE325B34e57E596139cC81f4E%2C0xB1d248BE37C19c3C1539be906c85c617A91323BA%2C0xffEeE0b83fd00032cB035b57a725C9670dfa6F2F%2C0xD7f09148Eb22686Cb5Dcbdd0cF27D04123d14c74"
      );
      if (!mantaData.ok) {
        throw new Error(
          `Manta Pacific Pools API error: ${mantaData.statusText}`
        );
      }
      const mantaJsonData = await mantaData.json();

      // Check if the user is an admin
      if (
        address &&
        (address.toLocaleLowerCase() ===
          "0x961ef0b358048d6e34bdd1ace00d72b37b9123d7" ||
          address.toLocaleLowerCase() ===
            "0x546734789339b93220df5e0365d7192e182a72a7")
      ) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }

      // List of contracts (for testnet)
      const flatArray = [
        "0xc1B20d25869d4deC96B9F679B997e3349db62C4c_93747",

        "0xB813E9C151f2381A52D6B2C7478AB0Dd4E5Afea8_168587773",
      ];

      // Combine jsonData and mantaJsonData
      const finalTvlJsonData = [...jsonData.data, ...mantaJsonData.data];

      // Fetch vault details by chain
      const valutList = await getValutDetailsByChain2(
        flatArray,
        finalTvlJsonData
      );
      const finalList2 = [...valutList];
      setvalutList(finalList2);
      // console.log("valutList set with:", finalList2);

      const valutList2 = await getValutDetailsByChain(
        flatArray,
        finalTvlJsonData
      );
      const finalList = [...valutList2];
      setvalutList(finalList);

      // Calculate totals
      setTotalVault(flatArray.length);
      // console.log("setTotalVault", flatArray.length);

      const totalOverallReturnVal = finalList.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.totalOverallreturnVal,
        0
      );
      setOverallReturn(totalOverallReturnVal.toFixed(2));

      const totalAverageApy = finalList.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.totalAverageApy,
        0
      );
      settotalAverageApy(totalAverageApy.toFixed(2));

      const totalTvlVal = finalList.reduce(
        (accumulator, currentValue) => accumulator + currentValue.tvlInUsd,
        0
      );
      setTotalTvl(totalTvlVal.toFixed(2));

      const portfolioVal = finalList2.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.totalPortfolio,
        0
      );

      setPortfolio(portfolioVal.toFixed(2));
    } catch (error) {
      console.error("Error in getDeployedValut:", error);
    } finally {
      setIsDataLoadng(false);
    }
  }

  const getAdminPanelDetails = async (valultList: string[], jsonData: any) => {
    const valutListVal = await Promise.all(
      valultList?.map(async (vaultAddress: any) => {
        const response = await fetch("/vaultDetails.json");
        const valutData = await response.json();
        const strategyValut = valutData[vaultAddress as string];
        const contract = getContract(
          strategyValut["strategyAddress"] as string,
          strategyContractAbi,
          signer
        );
        const lastHarvestdate = await contract.lastHarvest();
        const tokenID = await contract.tokenID();
        const nonfungiblePositionManager =
          await contract.NonfungiblePositionManager();
        const nonfungibleContract = getContract(
          nonfungiblePositionManager as string,
          nonfungibleContractAbi,
          signer
        );
        const nonfungibleDetails = await nonfungibleContract.positions(tokenID);
        const tickLower = nonfungibleDetails.tickLower;
        const tickUpper = nonfungibleDetails.tickUpper;

        const stakeAddress = await contract.stake();

        //call stake contract
        const stakecontract = getContract(
          stakeAddress as string,
          stakeContractAbi,
          signer
        );
        const stakecontractDetails = await stakecontract.slot0();

        const currentPrice = 1.0001 ^ stakecontractDetails.tick;
        const tickLowerPrice = 1.0001 ^ tickLower;
        const tickUpperPrice = 1.0001 ^ tickUpper;

        const currentAllocation =
          (Math.sqrt(tickUpperPrice / currentPrice) *
            (Math.sqrt(tickUpperPrice) - Math.sqrt(tickLowerPrice))) /
          (Math.sqrt(tickUpperPrice) - Math.sqrt(currentPrice));

        console.log("lastHarvestdate", lastHarvestdate);
      })
    );
  };

  // async function getDeployedValut() {
  //   setIsDataLoadng(true)
  //   //setLoading(true);

  //   //for show hide filter
  //   // const response = await fetch('/vaultDetails.json');
  //   // const data = await response.json();

  //   const bnbLocalProvider = new ethers.providers.JsonRpcProvider(RPCUrl);
  //   const bnbContract = getContract(whitelistedBNBFactoryAddress, pancakeWhitelistedVaultFactoryV2Json.abi, bnbLocalProvider);
  //   const mantleLocalProvied = new ethers.providers.JsonRpcProvider(mantleRPCUrl);
  //   //const mantleContract = getContract(whitelistedMantleFactoryAddress, pancakeWhitelistedVaultFactoryV2Json.abi, mantleLocalProvied.getSigner());
  //   const mantleContract2 = getContract(whitelistedMantle2FactoryAddress, pancakeWhitelistedVaultFactoryV2Json.abi, mantleLocalProvied);

  //   let bnbValutListVal = await bnbContract.listAllVaults();
  //   console.log("bnbValutListVal klop", bnbValutListVal);
  //   // let mantleValutListVal = await mantleContract.listAllVaults();

  //   //for show hide filter
  //   //mantleValutListVal = mantleValutListVal.reduce((final:any, val:any)=> ((checkShowHideLogic(data,val)) === true ? final.concat(val) : final),[]);

  //   let mantleValutListVal2 = await mantleContract2.listAllVaults();
  //   console.log("mantleValutListVal2", mantleValutListVal2);

  //   const bnbValutList = await getValutDetailsByChain2(bnbValutListVal, bnbLocalProvider);
  //   //const mantleValutListTest = await getValutDetailsByChain2(mantleValutListVal, mantleLocalProvied);
  //   const mantleValutListTest2 = await getValutDetailsByChain2(mantleValutListVal2, mantleLocalProvied);

  //   const finalListTest = [...mantleValutListTest2,...bnbValutList];
  //   setvalutList(finalListTest);

  //   setLoading(false);

  //   const bnbValutList2 = await getValutDetailsByChain(bnbValutListVal, bnbLocalProvider);
  //   //const mantleValutList = await getValutDetailsByChain(mantleValutListVal, mantleLocalProvied);
  //   const mantleValutList2 = await getValutDetailsByChain(mantleValutListVal2, mantleLocalProvied);

  //   const finalList2 = [...mantleValutList2, ...bnbValutList2];

  //   setvalutList(finalList);

  //   setTotalVault(finalList.length);

  //   const totalOverallreturnVal = finalList.reduce((accumulator, currentValue) => accumulator + currentValue.totalOverallreturnVal, 0);
  //   setOverallReturn(totalOverallreturnVal.toFixed(2));

  //   const totalAverageApy = finalList.reduce((accumulator, currentValue) => accumulator + currentValue.totalAverageApy, 0);
  //   settotalAverageApy(totalAverageApy.toFixed(2));

  //   const totalTvlVal = finalList.reduce((accumulator, currentValue) => accumulator + currentValue.tvlInUsd, 0);
  //   console.log("totalTvlVal", totalTvlVal);
  //   setTotalTvl(totalTvlVal.toFixed(2));

  //   const portfolioVal = finalList.reduce((accumulator, currentValue) => accumulator + currentValue.totalPortfolio, 0);
  //   setPortfolio(portfolioVal.toFixed(2));
  //   setIsDataLoadng(false);
  // }

  const goToUrl = (url: any) => {
    window.open(url, "_blank");
  };

  const gotoDiscord = () => {
    const url = "https://discord.com/invite/sbMxwS6VEV";
    window.open(url, "_blank");
  };

  const fnum = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  };

  const getSectionBackgroundImg = (strategyType: any, financeType: any) => {
    if (financeType === "fusionX") {
      if (strategyType === "stable") {
        return "bckgrnd_img_fusionX_card_stable";
      } else {
        return "bckgrnd_img_fusionX_card_steady";
      }
    } else if (financeType === "apertureswap") {
      if (strategyType === "stable") {
        return "bckgrnd_img_apertureswap_card_stable";
      } else {
        return "bckgrnd_img_apertureswap_card_steady";
      }
    } else {
      if (strategyType === "stable") {
        return "bckgrnd_img_agni_card_stable";
      } else {
        return "bckgrnd_img_agni_card_steady";
      }
    }
  };
  const handleButtonClick = (vaultAddress: string) => {
    window.location.href = `#/vault/${vaultAddress}`;
    window.scrollTo(0, 0);
  };
  // console.log("valutList", valutList);
  return (
    <>
      {loading ? (
        <>
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        <>
          <div className="custom-container">
            {isConnected ? (
              <>
                <div className="second_section outer_section_first">
                  <div className="dsp_prt_cont">
                    {isWhiteListed ? (
                      <>
                        <div className="homePort">
                          <div className="prtfol_mrgn">
                            <div className="first_sec_heder1">
                              Portfolio Holdings
                            </div>
                            <div className="first_sec_heder2">
                              {isDataLoadng ? (
                                <>
                                  <Skeleton
                                    width="5rem"
                                    className="mb-2"
                                  ></Skeleton>
                                </>
                              ) : (
                                <>${portfolio}</>
                              )}
                            </div>
                            {/* <div className='first_sec_dsp_intr mt-2'>
                          <div>
                            <span>PnL</span>
                            <br />
                            <span className='txt_clr_grn'>-</span>
                          </div>
                          <div>
                            <span>Absolute Returns</span>
                            <br />
                            <span className='txt_clr_grn'>-</span>
                          </div>
                        </div> */}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="wdth_40">
                          <div className="holding_header_inner secondary_color mb-2 redHatFont">
                            Join incentivized testnet
                          </div>
                          <div className="mb-3">
                            Help us build Rivera and win exclusive OG badges{" "}
                          </div>
                          <div>
                            <button
                              className="btn btn-riv-primary"
                              onClick={gotoDiscord}
                            >
                              Get Whitelisted
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="homePort_30">
                      <div className="tvl_back pddng_20">
                        <div className="dsp fnt_wgt_600">
                          <span className="op_60">TVL</span>
                          <div className="lqdt_icon_wdth_key">
                            <img src={keyCircleImg} alt="tvl" />
                          </div>
                        </div>
                        <div className="holding_header_inner secondary_color redHatFont">
                          {" "}
                          {isDataLoadng ? (
                            <>
                              <Skeleton
                                width="5rem"
                                className="mb-2"
                              ></Skeleton>
                            </>
                          ) : (
                            <>${fnum(totalTvl)}</>
                            // lamaTotalTvl
                          )}
                        </div>
                      </div>
                      <div className="dspl_between">
                        <div className="tvl_back pddng_20 width_48">
                          <div className="dsp fnt_wgt_600">
                            <span className="op_60">Vaults</span>
                            <div className="lqdt_icon_wdth_key">
                              <img src={keyCircleImg} alt="tvl" />
                            </div>
                          </div>
                          <div className="holding_header_inner redHatFont">
                            {valutList.length}
                          </div>
                        </div>
                        <div className="tvl_back pddng_20 width_48">
                          <div className="dsp fnt_wgt_600">
                            <span className="op_60">Automations </span>
                            <div className="lqdt_icon_wdth_key">
                              <img src={seetingCircleImg} alt="tvl" />
                            </div>
                          </div>
                          <div className="holding_header_inner redHatFont">
                            463
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        src={rocketImg}
                        alt="rocket img"
                        className="rocketImg"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="second_section outer_section_first">
                <div className="dsp_cont_first_section">
                  <div className="wdth_40_first">
                    <div className="holding_header_inner mb-2 redHatFont secondary_color">
                      Structured Market Making Vaults
                    </div>
                    <div className="mb-3">
                      {" "}
                      Explore strategies that suit your risk profile and easily
                      supply liquidity to the most capital-efficient DEXs.
                    </div>
                    <div className="mb_20">
                      <ConnectBtn />
                    </div>
                  </div>
                  <div className="wdth_30_first">
                    <div className="tvl_back pddng_20">
                      <div className="dsp fnt_wgt_600 ">
                        <span className="op_60">TVL</span>
                        <div className="lqdt_icon_wdth">
                          <img src={tvlIMg} alt="tvl" />
                        </div>
                      </div>
                      <div className="holding_header_inner secondary_color redHatFont">
                        {isDataLoadng ? (
                          <>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          </>
                        ) : (
                          <>${fnum(totalTvl)}</>
                        )}
                      </div>
                    </div>
                    <div className="dspl_between">
                      <div className="tvl_back pddng_20 width_48">
                        <div className="dsp fnt_wgt_600">
                          <span className="op_60">Vaults</span>
                          <div className="lqdt_icon_wdth_key">
                            <img src={keyCircleImg} alt="tvl" />
                          </div>
                        </div>
                        <div className="holding_header_inner redHatFont">
                          {valutList.length}
                        </div>
                      </div>
                      <div className="tvl_back pddng_20 width_48">
                        <div className="dsp fnt_wgt_600">
                          <span className="op_60">Automations </span>
                          <div className="lqdt_icon_wdth_key">
                            <img src={seetingCircleImg} alt="tvl" />
                          </div>
                        </div>
                        <div className="holding_header_inner redHatFont">
                          463
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <img
                      src={rocketImg}
                      alt="rocket img"
                      className="rocketImg"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="second_section row mt-5 mb-5">
              {/* <h4 className='valut_header redHatFont fnt_wgt_600'>Liquidity Management Vaults</h4> */}

              {valutList.map((e: any, index: any) => {
                return (
                  <div className="card_wdth_50" key={index}>
                    <div className="first_section outer_section">
                      {e.assetName === "WSVM" ? (
                        <div
                          className={`bckgrnd_img_card_fnt bckgrnd_new_testnet_new`}
                        >
                          {/* <div className={`bckgrnd_img_card_fnt ${(e.financeTypeVal === "fusionX")
                    ? (e.strategyTypeVal === "stable" ? "bckgrnd_img_fusionX_card_stable" : "bckgrnd_img_fusionX_card_steady")
                    : (e.financeTypeVal === "apertureswap" ? (e.strategyTypeVal === "stable" ? "bckgrnd_img_apertureswap_card_stable" : 
                    "bckgrnd_img_apertureswap_card_steady") : (e.strategyTypeVal === "stable" ? "bckgrnd_img_agni_card_stable" : "bckgrnd_img_agni_card_steady"))
                    }`}> */}
                          <div className="dsp_start">
                            <div className="d-flex align-items-start">
                              <div className="d-flex">
                                <img
                                  src={e.assetImg}
                                  alt="btc img"
                                  className="btc_asst_img_width"
                                />
                                <img
                                  src={e.assetImg2}
                                  alt="btc img"
                                  className="btc_img_width"
                                />
                              </div>
                              <div>
                                <div className="vault_header_font_size">
                                  {e.name}
                                </div>
                                <div className="card_snd_clr fnt_14 notMobileDevice">
                                  {e?.financeType}
                                </div>
                              </div>
                            </div>
                            <div className="notMobileDevice">
                              <span className="card_snd_clr">Fee Tier</span>
                              <br />
                              <span>{e.feeTier}%</span>
                            </div>
                            {/* <div>
                              <span className="holding_val ml_8">
                                {e.saftyRating}
                              </span>
                              {e.saftyRating ? (
                                <img
                                  src={saftyImg}
                                  alt="lock img"
                                  className="wthlist_back_img"
                                />
                              ) : (
                                <></>
                              )}
                            </div> */}
                            <div className="chain_pos_det">
                              <span>
                                <img src={e.chainImg} alt="chain" />
                              </span>
                            </div>
                          </div>
                          <div className="mobileDevice mt-2">
                            <div className="dsp_start">
                              <div>
                                <span className="card_snd_clr">Fee Tier</span>
                                <br />
                                <span>{e.feeTier}</span>
                              </div>
                              <div className="logoOnCard">
                                {/* <img src={e.poweredBy} alt='lock img' className='cashaa1_logo' /> */}
                                {/* <img src={e.lendedBy} alt='lend img' className='cashaa_logo' /> */}
                              </div>
                            </div>
                          </div>
                          <div className="vault_sub_header_font_size">
                            {e.strategyType}
                          </div>
                          <div className="pos_dp">
                            <div>
                              <div className="d-flex dspWrap mt-2 mb-4">
                                {isAdmin ? (
                                  <>
                                    {/* <div className='mr_20'>
                                24H change
                                <br />
                                <span className='holding_val'>+5%</span>
                              </div> */}

                                    <div className="mr_20">
                                      % utilisation
                                      <br />
                                      <span className="fnt_wgt_600">0%</span>
                                    </div>

                                    <div>
                                      Share Price
                                      <br />
                                      <span className="fnt_wgt_600">
                                        {e?.vaultShare?.toFixed(2)}{" "}
                                        {e.assetName}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {e?.isLiquidityMining ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={almImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Liquidity Mining
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isActiveStrategy ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={almImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Active Strategy
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isWideRange ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={almImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Wide Range
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isPeggedStrategy ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={dynamicStrategyImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Pegged Strategy
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isStaticRange ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={dynamicStrategyImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Static Range
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isDynamicStrategy ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={dynamicStrategyImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Dynamic
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isLSDFarming ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={LSDFarmingImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            LSD Farming
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isVolatilePair ? (
                                      <>
                                        {" "}
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={VolatilePairColorImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Volatile Pair
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isStablePair ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={StablePairColorImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Stable Pair
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            {e?.assetName === "WSVM" ? (
                              <div className="icecream">
                                <div className="">
                                  <img
                                    src={e.poweredBy}
                                    alt="lock img"
                                    className=""
                                    style={{ width: "200px" }}
                                  />
                                </div>
                                {/* Optionally include more content here */}
                              </div>
                            ) : (
                              <div className="weigh_machine">
                                <div className="">
                                  <img
                                    src={e.poweredBy}
                                    alt="lock img"
                                    className=""
                                  />
                                </div>
                                {/* <div className='mt-2 mb-4 notMobileDevice'><img src={e.lendedBy} alt='lend img' className='cashaa logo' /></div> */}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`bckgrnd_img_card_fnt bckgrnd_new_testnet`}
                        >
                          {/* <div className={`bckgrnd_img_card_fnt ${(e.financeTypeVal === "fusionX")
? (e.strategyTypeVal === "stable" ? "bckgrnd_img_fusionX_card_stable" : "bckgrnd_img_fusionX_card_steady")
: (e.financeTypeVal === "apertureswap" ? (e.strategyTypeVal === "stable" ? "bckgrnd_img_apertureswap_card_stable" : 
"bckgrnd_img_apertureswap_card_steady") : (e.strategyTypeVal === "stable" ? "bckgrnd_img_agni_card_stable" : "bckgrnd_img_agni_card_steady"))
}`}> */}
                          <div className="dsp_start">
                            <div className="d-flex align-items-start">
                              <div className="d-flex">
                                <img
                                  src={e.assetImg}
                                  alt="btc img"
                                  className="btc_asst_img_width"
                                />
                                <img
                                  src={e.assetImg2}
                                  alt="btc img"
                                  className="btc_img_width"
                                />
                              </div>
                              <div>
                                <div className="vault_header_font_size">
                                  {e.name}
                                </div>
                                <div className="card_snd_clr fnt_14 notMobileDevice">
                                  {e?.financeType}
                                </div>
                              </div>
                            </div>
                            <div className="notMobileDevice">
                              <span className="card_snd_clr">Fee Tier</span>
                              <br />
                              <span>{e.feeTier}%</span>
                            </div>
                            {/* <div>
                              <span className="holding_val ml_8">
                                {e.saftyRating}
                              </span>
                              {e.saftyRating ? (
                                <img
                                  src={saftyImg}
                                  alt="lock img"
                                  className="wthlist_back_img"
                                />
                              ) : (
                                <></>
                              )}
                            </div> */}
                            <div className="chain_pos_det">
                              <span>
                                <img src={e.chainImg} alt="chain" />
                              </span>
                            </div>
                          </div>
                          <div className="mobileDevice mt-2">
                            <div className="dsp_start">
                              <div>
                                <span className="card_snd_clr">Fee Tier</span>
                                <br />
                                <span>{e.feeTier}</span>
                              </div>
                              <div className="logoOnCard">
                                {/* <img src={e.poweredBy} alt='lock img' className='cashaa1_logo' /> */}
                                {/* <img src={e.lendedBy} alt='lend img' className='cashaa_logo' /> */}
                              </div>
                            </div>
                          </div>
                          <div className="vault_sub_header_font_size">
                            {e.strategyType}
                          </div>
                          <div className="pos_dp">
                            <div>
                              <div className="d-flex dspWrap mt-2 mb-4">
                                {isAdmin ? (
                                  <>
                                    {/* <div className='mr_20'>
         24H change
         <br />
         <span className='holding_val'>+5%</span>
       </div> */}

                                    <div className="mr_20">
                                      % utilisation
                                      <br />
                                      <span className="fnt_wgt_600">0%</span>
                                    </div>

                                    <div>
                                      Share Price
                                      <br />
                                      <span className="fnt_wgt_600">
                                        {e?.vaultShare?.toFixed(2)}{" "}
                                        {e.assetName}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {e?.isLiquidityMining ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={almImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Liquidity Mining
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isActiveStrategy ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={almImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Active Strategy
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isWideRange ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={almImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Wide Range
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isPeggedStrategy ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={dynamicStrategyImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Pegged Strategy
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isStaticRange ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={dynamicStrategyImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Static Range
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isDynamicStrategy ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={dynamicStrategyImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Dynamic
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isLSDFarming ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={LSDFarmingImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            LSD Farming
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isVolatilePair ? (
                                      <>
                                        {" "}
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={VolatilePairColorImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Volatile Pair
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}

                                    {e?.isStablePair ? (
                                      <>
                                        <div className="trdng_outer mr_12">
                                          {" "}
                                          <span className="trdng_width">
                                            <img
                                              src={StablePairColorImg}
                                              className="ml_8"
                                              alt="arrow img"
                                            />
                                            Stable Pair
                                          </span>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="weigh_machine">
                              <div className="">
                                <img
                                  src={e.poweredBy}
                                  alt="lock img"
                                  className=""
                                />
                              </div>
                              {/* <div className='mt-2 mb-4 notMobileDevice'><img src={e.lendedBy} alt='lend img' className='cashaa logo' /></div> */}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="pddng_15">
                        <div className="dsp_start mb-4">
                          <div className="wdth_card_55">
                            <div className="mb-1">TVL</div>
                            <span className="secondary_color fnt_wgt_600">
                              {fnum(Number(e.tvl))} {e.assetName}
                            </span>
                            <div className="d-flex">
                              <ProgressBar
                                value={Number(e.percentage)}
                                className="wdth_50"
                                showValue={false}
                              ></ProgressBar>
                              <div className="prgrs_txt">
                                {fnum(Number(e.tvlcap))} {e.assetName}
                              </div>
                            </div>
                          </div>
                          <div className="mr_card_38">
                            LP APR <br />{" "}
                            <div className="holding_val mt-1">
                              {e.averageApy} %
                            </div>
                          </div>
                        </div>
                        {isAdmin ? (
                          <>
                            <div className="dsp mb-4">
                              <div>
                                Last Harvest <br />
                                <span className="vault_header_font_size">
                                  {e?.lastHarvestdate}
                                </span>
                              </div>
                              <div>
                                Total unharvested rewards <br />
                                <span className="vault_header_font_size">
                                  {" "}
                                  $0{" "}
                                </span>
                              </div>
                              <div>
                                Harvesting Factor <br />
                                <span className="vault_header_font_size">
                                  0.01%
                                </span>
                              </div>
                            </div>
                            <div className="dsp mb-4">
                              <div>
                                Current Price
                                <br />
                                <span className="vault_header_font_size">
                                  {e?.currentPrice} {e?.assetName}
                                </span>
                              </div>
                              <div>
                                Current Range <br />
                                <span className="vault_header_font_size">
                                  {e?.tickLowerPrice}-{e?.tickUpperPrice}{" "}
                                  {e?.assetName}{" "}
                                </span>
                              </div>
                              <div>
                                Current Allocation <br />
                                <span className="vault_header_font_size">
                                  {e?.currentAllocation}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="dsp_around mb-2">
                          <div className="wdth_100">
                            {" "}
                            <a
                              className="btn btn-riv-secondary view_btn_wdth"
                              onClick={() => handleButtonClick(e.valutAddress)}
                              href={`#/vault/${e.valutAddress}`}
                            >
                              {viewDetailsbuttonVal}
                            </a>
                          </div>
                          {isAdmin ? (
                            <>
                              <div className="wdth_100 ml_15">
                                <a
                                  className="btn btn-riv-secondary view_btn_wdth"
                                  href={`#/vaultManager/${e.valutAddress}`}
                                >
                                  {managebuttonVal}
                                </a>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="second_section outer_section_last last_section_back mb-5">
              <div className="d-flex align-items-center ml_25">
                <div className="">
                  <div className="last_header_inner redHatFont">
                    Asset Manager?
                  </div>
                  <div className="last_section_desc">
                    Provide peace of mind to your investors by offering them
                    self-custody vaults. Build & customize powerful yield
                    farming and structured products.{" "}
                  </div>
                  <div>
                    <a
                      className="btn btn-riv-secondary earlyacesBtn"
                      href={`https://tally.so/r/m6Lope`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Get Early Access
                    </a>
                  </div>
                </div>
                <div className="">
                  <img
                    src={assetsManagerImg}
                    alt="assets manager"
                    className="rocketImg wdth_275px"
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
