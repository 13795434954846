import './App.css';
import React, { useState, useEffect } from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import ConnectBtn from './ConnectBtn';
import keyCircleImg from './assets/images/keyCircle2.svg';
import seetingCircleImg from './assets/images/seetingCircle2.svg';
import rocketImg from './assets/images/rocket.png';
import tvlIMg from './assets/images/tvlv2.svg';
import { useAccount } from 'wagmi';
import { Skeleton } from 'primereact/skeleton';

function DexDetails() {
  const { address, isConnected } = useAccount();
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  let { chainName, exchangeName } = useParams();
  const navigate = useNavigate();

  // const networkOptions = useState<{ label: string; value: string }[]>([
  //   { label: 'Mantle', value: 'mantle' },
  //   { label: 'Manta Network', value: 'manta' },
  //   { label: 'Base', value: 'base' }
  // ]);
  const [networkOptions, setNetworkOptions] = useState<{ label: string; value: string; src: string }[]>([
    { label: 'Mantle', value: 'mantle', src: 'img/filter/mantleFilter.svg' },
    { label: 'Manta Network', value: 'manta', src: 'img/filter/mantaFilter.svg' },
    { label: 'Base', value: 'base', src: 'img/filter/baseFillter.svg' }
  ]);


  const [network, setNetwork] = useState(chainName);

  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  }

  const getFormated = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  }

  const [portfolio, setPortfolio] = useState(""); //need to check logic
  const [totalVault, setTotalVault] = useState(0); //will get this valur from backend api
  const [totalTvlInUsd, setTotalTvlInUsd] = useState(""); //will get this valur from backend api

  const loadData = (e: any) => {
    console.log("e", e);
    setNetwork(e);
    navigate(e);
  }


  const RadioInput = ({ label, value, src }: { label: string, value: string, src:string }) => {
    return (
      <>
        <div className='radioSectionChild'>
          <input
            type="radio"
            checked={network === value}
            onChange={() => loadData(value)}
            id={value}
          />
          <label className='radioButtonLevel' htmlFor={value}>
            <img src={src} alt='' className='tvlChainFilter' />
            <span>{label}</span>
          </label>
        </div>
      </>

    );
  };


  return (
    <>
      <div className='custom-container'>
        {/* <div className='outer_section_first pddng_15'>
          <div>Protocol Stats</div>
          <div className='d-flex mt-2'>
            <div className='mr_20'>Total Value Locked<br /><span>$73.83M</span></div>
            <div className='mr_20'>Total Fees Accrued<br /><span>$14.32M</span></div>
            <div className='mr_20'>Staking APY<br /><span>1.93%</span></div>
          </div>
        </div> */}

        {isConnected ?
          <>
            <div className='second_section outer_section_first'>
              <div className='dsp_prt_cont'>
                <div className='homePort'>
                  <div className='prtfol_mrgn'>
                    <div className='first_sec_heder1'>Portfolio Holdings</div>
                    <div className='first_sec_heder2'>
                      {isDataLoadng ? <><Skeleton width="5rem" className="mb-2"></Skeleton></> : <>${portfolio}</>}
                    </div>
                  </div>
                </div>

                <div className='homePort_30'>
                  <div className='tvl_back pddng_20'>
                    <div className='dsp fnt_wgt_600'>
                      <span className='op_60'>TVL</span>
                      <div className='lqdt_icon_wdth_key'><img src={keyCircleImg} alt='tvl' /></div>
                    </div>
                    <div className='holding_header_inner secondary_color redHatFont'> {isDataLoadng ? <><Skeleton width="5rem" className="mb-2"></Skeleton></> : <>${getFormated(totalTvlInUsd)}</>}</div>
                  </div>
                  <div className='dspl_between'>
                    <div className='tvl_back pddng_20 width_48'>
                      <div className='dsp fnt_wgt_600'>
                        <span className='op_60'>Vaults</span>
                        <div className='lqdt_icon_wdth_key'><img src={keyCircleImg} alt='tvl' /></div>
                      </div>
                      <div className='holding_header_inner redHatFont'>{totalVault}</div>
                    </div>
                    <div className='tvl_back pddng_20 width_48'>
                      <div className='dsp fnt_wgt_600'>
                        <span className='op_60'>Staking APY </span>
                        <div className='lqdt_icon_wdth_key'><img src={seetingCircleImg} alt='tvl' /></div>
                      </div>
                      <div className='holding_header_inner redHatFont'>1.93%</div>
                    </div>
                  </div>
                </div>
                <div >
                  <img src={rocketImg} alt='rocket img' className='rocketImg' />
                </div>
              </div>
            </div>

          </>
          :
          <div className='second_section outer_section_first'>
            <div className='dsp_cont_first_section'>
              <div className='wdth_40_first'>
                <div className='holding_header_inner mb-2 redHatFont secondary_color'>Concentrated Liquidity, Simplified!</div>
                <div className='mb-3'>Earn passive yield with active market-making strategies.</div>
                <div className='mb_20'><ConnectBtn /></div>
              </div>
              <div className='wdth_30_first'>
                <div className='tvl_back pddng_20'>
                  <div className='dsp fnt_wgt_600 '>
                    <span className='op_60'>TVL</span>
                    <div className='lqdt_icon_wdth'>
                      <img src={tvlIMg} alt='tvl' />
                    </div>
                  </div>
                  <div className='holding_header_inner secondary_color redHatFont'>{isDataLoadng ? <><Skeleton width="5rem" className="mb-2"></Skeleton></> : <>${getFormated(totalTvlInUsd)}</>}</div>
                </div>
                <div className='dspl_between'>
                  <div className='tvl_back pddng_20 width_48'>
                    <div className='dsp fnt_wgt_600'>
                      <span className='op_60'>Vaults</span>
                      <div className='lqdt_icon_wdth_key'><img src={keyCircleImg} alt='tvl' /></div></div>
                    <div className='holding_header_inner redHatFont'>{totalVault}</div>
                  </div>
                  <div className='tvl_back pddng_20 width_48'>
                    <div className='dsp fnt_wgt_600'>
                      <span className='op_60'>Staking APY </span>
                      <div className='lqdt_icon_wdth_key'><img src={seetingCircleImg} alt='tvl' /></div></div>
                    <div className='holding_header_inner redHatFont'>1.93%</div>
                  </div>
                </div>


              </div>
              <div >
                <img src={rocketImg} alt='rocket img' className='rocketImg' />
              </div>
            </div>
          </div>
        }


        <div className='second_section row mt-5 mb-5'>
          <span className="redHatFont fnt_wgt_600 mb-2">TVL by Chain</span>

          <div className="flex justify-content-center mb-4">
            <section className='radioSection'>
              {networkOptions.map(e => (
                <RadioInput label={e?.label} value={e.value} src={e.src} />
              ))}
            </section>

            {/* <SelectButton value={network} onChange={(e) => loadData(e)} options={networkOptions} /> */}
          </div>

          <Outlet />

        </div>
      </div>
    </>
  );
}

export default DexDetails;
