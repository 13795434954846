import { BigNumber, EventFilter, ethers } from "ethers";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAccount,
  useBalance,
  useNetwork,
  useProvider,
  useSigner,
  useSwitchNetwork,
} from "wagmi";
import { TabView, TabPanel } from "primereact/tabview";
import { ProductService } from "./service/ProductService";
import { Accordion, AccordionTab } from "primereact/accordion";
import { ProgressBar } from "primereact/progressbar";
import lockImg from "./assets/images/lock.png";
import upImg from "./assets/images/up.png";
import downImg from "./assets/images/down.png";
import helpImg from "./assets/images/help-circle.png";
import venusImg from "./assets/images/venus.svg";
import globeImg from "./assets/images/globe.png";
import arrowUpImg from "./assets/images/arrow-up-right.png";
import copyImg from "./assets/images/copy.png";
import eternalLinkImg from "./assets/images/external-link.png";
import bnbImg from "./assets/images/bnb.png";
import ethImg from "./assets/images/eth.png";
import erc20Json from "./abi/out/ERC20.sol/ERC20.json";
import dollarImg from "./assets/images/dollar.png";
import saftyImg from "./assets/images/safty.png";
import graphIMg from "./assets/images/graph.png";
import zapIMg from "./assets/images/zap.png";
import riveraAutoCompoundingVaultV2WhitelistedJson from "./abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import RiveraAutoCompoundingVaultV2WhitelistedJson from "./abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import cakeLpStakingV2Json from "./abi/out/CakeLpStakingV2.sol/CakeLpStakingV2.json";
import {
  FACTORY_CONTRACT_DEPLOYMENT_BLOCK,
  RPCUrl,
  mantleRPCUrl,
} from "./constants/global.js";
import pancakeFullImg from "./assets/images/pancakeFull.svg";
import StablePairColorImg from "./assets/images/StablePairColor.svg";
import VolatilePairColorImg from "./assets/images/volatilePair.png";
import dynamicStrategyImg from "./assets/images/dynamicStrategy.png";
import almImg from "./assets/images/alm.svg";
import LSDFarmingImg from "./assets/images/LSDFarming.svg";
import bitLogoImg from "./assets/images/bitLogo.png";
import { Toast } from "primereact/toast";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import wmntAbiJson from "./assets/json/wmntAbi.json";
import Decimal from "decimal.js";
import depositTabImg from "./assets/images/depositTab.svg";
import withdrwaTabImg from "./assets/images/withdrwaTab.svg";
import gifPPImg from "./assets/images/gifPP.svg";
import waitingImg from "./assets/images/waiting.gif";
import successfullImg from "./assets/images/successfull.gif";
import { Dialog } from "primereact/dialog";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip as TooltipChart,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import ConnectBtn from "./ConnectBtn";
import infoCircleImg from "./assets/images/info_circle.svg";
import { Tooltip } from "primereact/tooltip";
import { Skeleton } from "primereact/skeleton";

interface Product {
  id: string;
  code: string;
  name: string;
  description: string;
  image: string;
  price: number;
  category: string;
  quantity: number;
  inventoryStatus: string;
  rating: number;
}

export default function VaultDetails() {
  const nonfungibleContractAbi = [
    {
      type: "constructor",
      inputs: [
        { type: "address", name: "_deployer", internalType: "address" },
        { type: "address", name: "_factory", internalType: "address" },
        { type: "address", name: "_WETH9", internalType: "address" },
        { type: "address", name: "_tokenDescriptor_", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bytes32", name: "", internalType: "bytes32" }],
      name: "DOMAIN_SEPARATOR",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bytes32", name: "", internalType: "bytes32" }],
      name: "PERMIT_TYPEHASH",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "WETH9",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "approve",
      inputs: [
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "balanceOf",
      inputs: [{ type: "address", name: "owner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "pure",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "baseURI",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "burn",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "collect",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType: "struct INonfungiblePositionManager.CollectParams",
          components: [
            { type: "uint256" },
            { type: "address" },
            { type: "uint128" },
            { type: "uint128" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [{ type: "address", name: "pool", internalType: "address" }],
      name: "createAndInitializePoolIfNecessary",
      inputs: [
        { type: "address", name: "token0", internalType: "address" },
        { type: "address", name: "token1", internalType: "address" },
        { type: "uint24", name: "fee", internalType: "uint24" },
        { type: "uint160", name: "sqrtPriceX96", internalType: "uint160" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "decreaseLiquidity",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType:
            "struct INonfungiblePositionManager.DecreaseLiquidityParams",
          components: [
            { type: "uint256" },
            { type: "uint128" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "deployer",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "factory",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "fusionXV3MintCallback",
      inputs: [
        { type: "uint256", name: "amount0Owed", internalType: "uint256" },
        { type: "uint256", name: "amount1Owed", internalType: "uint256" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "getApproved",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "increaseLiquidity",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType:
            "struct INonfungiblePositionManager.IncreaseLiquidityParams",
          components: [
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "isApprovedForAll",
      inputs: [
        { type: "address", name: "owner", internalType: "address" },
        { type: "address", name: "operator", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [
        { type: "uint256", name: "tokenId", internalType: "uint256" },
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "mint",
      inputs: [
        {
          type: "tuple",
          name: "params",
          internalType: "struct INonfungiblePositionManager.MintParams",
          components: [
            { type: "address" },
            { type: "address" },
            { type: "uint24" },
            { type: "int24" },
            { type: "int24" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "address" },
            { type: "uint256" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [{ type: "bytes[]", name: "results", internalType: "bytes[]" }],
      name: "multicall",
      inputs: [{ type: "bytes[]", name: "data", internalType: "bytes[]" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "name",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "ownerOf",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "permit",
      inputs: [
        { type: "address", name: "spender", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
        { type: "uint256", name: "deadline", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint96", name: "nonce", internalType: "uint96" },
        { type: "address", name: "operator", internalType: "address" },
        { type: "address", name: "token0", internalType: "address" },
        { type: "address", name: "token1", internalType: "address" },
        { type: "uint24", name: "fee", internalType: "uint24" },
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        {
          type: "uint256",
          name: "feeGrowthInside0LastX128",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "feeGrowthInside1LastX128",
          internalType: "uint256",
        },
        { type: "uint128", name: "tokensOwed0", internalType: "uint128" },
        { type: "uint128", name: "tokensOwed1", internalType: "uint128" },
      ],
      name: "positions",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "refundETH",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "safeTransferFrom",
      inputs: [
        { type: "address", name: "from", internalType: "address" },
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "safeTransferFrom",
      inputs: [
        { type: "address", name: "from", internalType: "address" },
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
        { type: "bytes", name: "_data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermit",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "value", internalType: "uint256" },
        { type: "uint256", name: "deadline", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermitAllowed",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "nonce", internalType: "uint256" },
        { type: "uint256", name: "expiry", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermitAllowedIfNecessary",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "nonce", internalType: "uint256" },
        { type: "uint256", name: "expiry", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "selfPermitIfNecessary",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "value", internalType: "uint256" },
        { type: "uint256", name: "deadline", internalType: "uint256" },
        { type: "uint8", name: "v", internalType: "uint8" },
        { type: "bytes32", name: "r", internalType: "bytes32" },
        { type: "bytes32", name: "s", internalType: "bytes32" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setApprovalForAll",
      inputs: [
        { type: "address", name: "operator", internalType: "address" },
        { type: "bool", name: "approved", internalType: "bool" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "supportsInterface",
      inputs: [{ type: "bytes4", name: "interfaceId", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "sweepToken",
      inputs: [
        { type: "address", name: "token", internalType: "address" },
        { type: "uint256", name: "amountMinimum", internalType: "uint256" },
        { type: "address", name: "recipient", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "symbol",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tokenByIndex",
      inputs: [{ type: "uint256", name: "index", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tokenOfOwnerByIndex",
      inputs: [
        { type: "address", name: "owner", internalType: "address" },
        { type: "uint256", name: "index", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "tokenURI",
      inputs: [{ type: "uint256", name: "tokenId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalSupply",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "transferFrom",
      inputs: [
        { type: "address", name: "from", internalType: "address" },
        { type: "address", name: "to", internalType: "address" },
        { type: "uint256", name: "tokenId", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "unwrapWETH9",
      inputs: [
        { type: "uint256", name: "amountMinimum", internalType: "uint256" },
        { type: "address", name: "recipient", internalType: "address" },
      ],
    },
    {
      type: "event",
      name: "Approval",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "address", name: "approved", indexed: true },
        { type: "uint256", name: "tokenId", indexed: true },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "ApprovalForAll",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "address", name: "operator", indexed: true },
        { type: "bool", name: "approved", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Collect",
      inputs: [
        { type: "uint256", name: "tokenId", indexed: true },
        { type: "address", name: "recipient", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "DecreaseLiquidity",
      inputs: [
        { type: "uint256", name: "tokenId", indexed: true },
        { type: "uint128", name: "liquidity", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "IncreaseLiquidity",
      inputs: [
        { type: "uint256", name: "tokenId", indexed: true },
        { type: "uint128", name: "liquidity", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Transfer",
      inputs: [
        { type: "address", name: "from", indexed: true },
        { type: "address", name: "to", indexed: true },
        { type: "uint256", name: "tokenId", indexed: true },
      ],
      anonymous: false,
    },
    { type: "receive" },
  ];
  const strategyContractAbi = [
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "NonfungiblePositionManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "acceptManagership",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "acceptOwnership",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "assettoNativeFeed",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "balanceOf",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "changeRange",
      inputs: [
        { type: "int24", name: "_tickLower", internalType: "int24" },
        { type: "int24", name: "_tickUpper", internalType: "int24" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "deposit",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "depositToken",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "feeDecimals",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "fullMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "fundManagerFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "harvest",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "inCaseTokensGetStuck",
      inputs: [{ type: "address", name: "_token", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "init",
      inputs: [
        {
          type: "tuple",
          name: "_riveraLpStakingParams",
          internalType: "struct RiveraLpStakingParams",
          components: [
            { type: "int24" },
            { type: "int24" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
          ],
        },
        {
          type: "tuple",
          name: "_commonAddresses",
          internalType: "struct CommonAddresses",
          components: [
            { type: "address" },
            { type: "address" },
            { type: "address" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "uint256" },
            { type: "address" },
            { type: "address" },
            { type: "address" },
          ],
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "lastHarvest",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "liquidityAmountsLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "liquidity", internalType: "uint128" },
      ],
      name: "liquidityBalance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "liquidityMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "lpFeesDepositToken",
          internalType: "uint256",
        },
      ],
      name: "lpRewardsAvailable",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "lpToken0",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "lpToken1",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "manager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
      name: "onERC721Received",
      inputs: [
        { type: "address", name: "", internalType: "address" },
        { type: "address", name: "", internalType: "address" },
        { type: "uint256", name: "", internalType: "uint256" },
        { type: "bytes", name: "", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [],
      name: "onlyManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [],
      name: "onlyVault",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "owner",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "panic",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "partner",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "partnerFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "pause",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "paused",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "pendingOwner",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint24", name: "", internalType: "uint24" }],
      name: "poolFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "protocolFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "renounceOwnership",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "retireStrat",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "router",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "safeCastLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setAssettoNativeFeed",
      inputs: [
        {
          type: "address",
          name: "assettoNativeFeed_",
          internalType: "address",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setManager",
      inputs: [{ type: "address", name: "_manager", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "sqrtPriceMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "stake",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "int24", name: "", internalType: "int24" }],
      name: "tickLower",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "tickMathLib",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "int24", name: "", internalType: "int24" }],
      name: "tickUpper",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tokenID",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "transferOwnership",
      inputs: [{ type: "address", name: "newOwner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "unpause",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "vault",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "withdraw",
      inputs: [{ type: "uint256", name: "_amount", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "withdrawFee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "withdrawFeeDecimals",
      inputs: [],
    },
    {
      type: "event",
      name: "AssetToNativeFeedChange",
      inputs: [
        { type: "address", name: "oldFeed", indexed: false },
        { type: "address", name: "newFeed", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Deposit",
      inputs: [
        { type: "uint256", name: "tvl", indexed: false },
        { type: "uint256", name: "amount", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Initialized",
      inputs: [{ type: "uint8", name: "version", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "OwnershipTransferStarted",
      inputs: [
        { type: "address", name: "previousOwner", indexed: true },
        { type: "address", name: "newOwner", indexed: true },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "OwnershipTransferred",
      inputs: [
        { type: "address", name: "previousOwner", indexed: true },
        { type: "address", name: "newOwner", indexed: true },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Paused",
      inputs: [{ type: "address", name: "account", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "RangeChange",
      inputs: [
        { type: "int24", name: "tickLower", indexed: false },
        { type: "int24", name: "tickUpper", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "SetManager",
      inputs: [{ type: "address", name: "manager", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "StratHarvest",
      inputs: [
        { type: "address", name: "harvester", indexed: true },
        { type: "uint256", name: "tvl", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Unpaused",
      inputs: [{ type: "address", name: "account", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "Withdraw",
      inputs: [
        { type: "uint256", name: "tvl", indexed: false },
        { type: "uint256", name: "amount", indexed: false },
      ],
      anonymous: false,
    },
  ];
  const stakeContractAbi = [
    { type: "constructor", inputs: [] },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "burn",
      inputs: [
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "amount", internalType: "uint128" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint128", name: "amount0", internalType: "uint128" },
        { type: "uint128", name: "amount1", internalType: "uint128" },
      ],
      name: "collect",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "amount0Requested", internalType: "uint128" },
        { type: "uint128", name: "amount1Requested", internalType: "uint128" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint128", name: "amount0", internalType: "uint128" },
        { type: "uint128", name: "amount1", internalType: "uint128" },
      ],
      name: "collectProtocol",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "uint128", name: "amount0Requested", internalType: "uint128" },
        { type: "uint128", name: "amount1Requested", internalType: "uint128" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "factory",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint24", name: "", internalType: "uint24" }],
      name: "fee",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "feeGrowthGlobal0X128",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "feeGrowthGlobal1X128",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "flash",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "increaseObservationCardinalityNext",
      inputs: [
        {
          type: "uint16",
          name: "observationCardinalityNext",
          internalType: "uint16",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "initialize",
      inputs: [
        { type: "uint160", name: "sqrtPriceX96", internalType: "uint160" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint128", name: "", internalType: "uint128" }],
      name: "liquidity",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IFusionXV3LmPool",
        },
      ],
      name: "lmPool",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint128", name: "", internalType: "uint128" }],
      name: "maxLiquidityPerTick",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "uint256", name: "amount0", internalType: "uint256" },
        { type: "uint256", name: "amount1", internalType: "uint256" },
      ],
      name: "mint",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
        { type: "uint128", name: "amount", internalType: "uint128" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint32", name: "blockTimestamp", internalType: "uint32" },
        { type: "int56", name: "tickCumulative", internalType: "int56" },
        {
          type: "uint160",
          name: "secondsPerLiquidityCumulativeX128",
          internalType: "uint160",
        },
        { type: "bool", name: "initialized", internalType: "bool" },
      ],
      name: "observations",
      inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "int56[]", name: "tickCumulatives", internalType: "int56[]" },
        {
          type: "uint160[]",
          name: "secondsPerLiquidityCumulativeX128s",
          internalType: "uint160[]",
        },
      ],
      name: "observe",
      inputs: [
        { type: "uint32[]", name: "secondsAgos", internalType: "uint32[]" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "liquidity", internalType: "uint128" },
        {
          type: "uint256",
          name: "feeGrowthInside0LastX128",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "feeGrowthInside1LastX128",
          internalType: "uint256",
        },
        { type: "uint128", name: "tokensOwed0", internalType: "uint128" },
        { type: "uint128", name: "tokensOwed1", internalType: "uint128" },
      ],
      name: "positions",
      inputs: [{ type: "bytes32", name: "", internalType: "bytes32" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "token0", internalType: "uint128" },
        { type: "uint128", name: "token1", internalType: "uint128" },
      ],
      name: "protocolFees",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setFeeProtocol",
      inputs: [
        { type: "uint32", name: "feeProtocol0", internalType: "uint32" },
        { type: "uint32", name: "feeProtocol1", internalType: "uint32" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setLmPool",
      inputs: [{ type: "address", name: "_lmPool", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint160", name: "sqrtPriceX96", internalType: "uint160" },
        { type: "int24", name: "tick", internalType: "int24" },
        { type: "uint16", name: "observationIndex", internalType: "uint16" },
        {
          type: "uint16",
          name: "observationCardinality",
          internalType: "uint16",
        },
        {
          type: "uint16",
          name: "observationCardinalityNext",
          internalType: "uint16",
        },
        { type: "uint32", name: "feeProtocol", internalType: "uint32" },
        { type: "bool", name: "unlocked", internalType: "bool" },
      ],
      name: "slot0",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "int56", name: "tickCumulativeInside", internalType: "int56" },
        {
          type: "uint160",
          name: "secondsPerLiquidityInsideX128",
          internalType: "uint160",
        },
        { type: "uint32", name: "secondsInside", internalType: "uint32" },
      ],
      name: "snapshotCumulativesInside",
      inputs: [
        { type: "int24", name: "tickLower", internalType: "int24" },
        { type: "int24", name: "tickUpper", internalType: "int24" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        { type: "int256", name: "amount0", internalType: "int256" },
        { type: "int256", name: "amount1", internalType: "int256" },
      ],
      name: "swap",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "bool", name: "zeroForOne", internalType: "bool" },
        { type: "int256", name: "amountSpecified", internalType: "int256" },
        { type: "uint160", name: "sqrtPriceLimitX96", internalType: "uint160" },
        { type: "bytes", name: "data", internalType: "bytes" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "tickBitmap",
      inputs: [{ type: "int16", name: "", internalType: "int16" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "int24", name: "", internalType: "int24" }],
      name: "tickSpacing",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint128", name: "liquidityGross", internalType: "uint128" },
        { type: "int128", name: "liquidityNet", internalType: "int128" },
        {
          type: "uint256",
          name: "feeGrowthOutside0X128",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "feeGrowthOutside1X128",
          internalType: "uint256",
        },
        { type: "int56", name: "tickCumulativeOutside", internalType: "int56" },
        {
          type: "uint160",
          name: "secondsPerLiquidityOutsideX128",
          internalType: "uint160",
        },
        { type: "uint32", name: "secondsOutside", internalType: "uint32" },
        { type: "bool", name: "initialized", internalType: "bool" },
      ],
      name: "ticks",
      inputs: [{ type: "int24", name: "", internalType: "int24" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "token0",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "token1",
      inputs: [],
    },
    {
      type: "event",
      name: "Burn",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "int24", name: "tickLower", indexed: true },
        { type: "int24", name: "tickUpper", indexed: true },
        { type: "uint128", name: "amount", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Collect",
      inputs: [
        { type: "address", name: "owner", indexed: true },
        { type: "address", name: "recipient", indexed: false },
        { type: "int24", name: "tickLower", indexed: true },
        { type: "int24", name: "tickUpper", indexed: true },
        { type: "uint128", name: "amount0", indexed: false },
        { type: "uint128", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "CollectProtocol",
      inputs: [
        { type: "address", name: "sender", indexed: true },
        { type: "address", name: "recipient", indexed: true },
        { type: "uint128", name: "amount0", indexed: false },
        { type: "uint128", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Flash",
      inputs: [
        { type: "address", name: "sender", indexed: true },
        { type: "address", name: "recipient", indexed: true },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
        { type: "uint256", name: "paid0", indexed: false },
        { type: "uint256", name: "paid1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "IncreaseObservationCardinalityNext",
      inputs: [
        {
          type: "uint16",
          name: "observationCardinalityNextOld",
          indexed: false,
        },
        {
          type: "uint16",
          name: "observationCardinalityNextNew",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Initialize",
      inputs: [
        { type: "uint160", name: "sqrtPriceX96", indexed: false },
        { type: "int24", name: "tick", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Mint",
      inputs: [
        { type: "address", name: "sender", indexed: false },
        { type: "address", name: "owner", indexed: true },
        { type: "int24", name: "tickLower", indexed: true },
        { type: "int24", name: "tickUpper", indexed: true },
        { type: "uint128", name: "amount", indexed: false },
        { type: "uint256", name: "amount0", indexed: false },
        { type: "uint256", name: "amount1", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "SetFeeProtocol",
      inputs: [
        { type: "uint32", name: "feeProtocol0Old", indexed: false },
        { type: "uint32", name: "feeProtocol1Old", indexed: false },
        { type: "uint32", name: "feeProtocol0New", indexed: false },
        { type: "uint32", name: "feeProtocol1New", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "SetLmPoolEvent",
      inputs: [{ type: "address", name: "addr", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "Swap",
      inputs: [
        { type: "address", name: "sender", indexed: true },
        { type: "address", name: "recipient", indexed: true },
        { type: "int256", name: "amount0", indexed: false },
        { type: "int256", name: "amount1", indexed: false },
        { type: "uint160", name: "sqrtPriceX96", indexed: false },
        { type: "uint128", name: "liquidity", indexed: false },
        { type: "int24", name: "tick", indexed: false },
        { type: "uint128", name: "protocolFeesToken0", indexed: false },
        { type: "uint128", name: "protocolFeesToken1", indexed: false },
      ],
      anonymous: false,
    },
  ];

  const vaultAbi = [
    {
      inputs: [
        {
          internalType: "address",
          name: "asset_",
          type: "address",
        },
        {
          internalType: "string",
          name: "_name",
          type: "string",
        },
        {
          internalType: "string",
          name: "_symbol",
          type: "string",
        },
        {
          internalType: "uint256",
          name: "_approvalDelay",
          type: "uint256",
        },
        {
          internalType: "uint256",
          name: "_totalTvlCap",
          type: "uint256",
        },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
      ],
      name: "Deposit",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint8",
          name: "version",
          type: "uint8",
        },
      ],
      name: "Initialized",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "implementation",
          type: "address",
        },
      ],
      name: "NewStratCandidate",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "uint256",
          name: "sharePriceX96",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "unutilizedAssetBal",
          type: "uint256",
        },
      ],
      name: "SharePriceChange",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "onwer_",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "oldTvlCap",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "newTvlCap",
          type: "uint256",
        },
      ],
      name: "TvlCapChange",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: "address",
          name: "implementation",
          type: "address",
        },
      ],
      name: "UpgradeStrat",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "onwer_",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "user",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "oldTvlCap",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "newTvlCap",
          type: "uint256",
        },
      ],
      name: "UserTvlCapChange",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "sender",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "receiver",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
      ],
      name: "Withdraw",
      type: "event",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
      ],
      name: "allowance",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "approvalDelay",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "approve",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "asset",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "balanceOf",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
      ],
      name: "convertToAssets",
      outputs: [
        {
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
      ],
      name: "convertToShares",
      outputs: [
        {
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "decimals",
      outputs: [
        {
          internalType: "uint8",
          name: "",
          type: "uint8",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "subtractedValue",
          type: "uint256",
        },
      ],
      name: "decreaseAllowance",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
      ],
      name: "deposit",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "getStratProposal",
      outputs: [
        {
          components: [
            {
              internalType: "address",
              name: "implementation",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "proposedTime",
              type: "uint256",
            },
          ],
          internalType: "struct StratCandidate",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_token",
          type: "address",
        },
      ],
      name: "inCaseTokensGetStuck",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "addedValue",
          type: "uint256",
        },
      ],
      name: "increaseAllowance",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "contract IStrategy",
          name: "_strategy",
          type: "address",
        },
      ],
      name: "init",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
      ],
      name: "maxDeposit",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
      ],
      name: "maxMint",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "maxRedeem",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "maxWithdraw",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
      ],
      name: "mint",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
      ],
      name: "previewDeposit",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
      ],
      name: "previewMint",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
      ],
      name: "previewRedeem",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
      ],
      name: "previewWithdraw",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "_implementation",
          type: "address",
        },
      ],
      name: "proposeStrat",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "shares",
          type: "uint256",
        },
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "redeem",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "renounceOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "totalTvlCap_",
          type: "uint256",
        },
      ],
      name: "setTotalTvlCap",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "user_",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "userTvlCap_",
          type: "uint256",
        },
      ],
      name: "setUserTvlCap",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "stratCandidate",
      outputs: [
        {
          internalType: "address",
          name: "implementation",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "proposedTime",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "strategy",
      outputs: [
        {
          internalType: "contract IStrategy",
          name: "",
          type: "address",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "symbol",
      outputs: [
        {
          internalType: "string",
          name: "",
          type: "string",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalAssets",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalTvlCap",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "transfer",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "from",
          type: "address",
        },
        {
          internalType: "address",
          name: "to",
          type: "address",
        },
        {
          internalType: "uint256",
          name: "amount",
          type: "uint256",
        },
      ],
      name: "transferFrom",
      outputs: [
        {
          internalType: "bool",
          name: "",
          type: "bool",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "upgradeStrat",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "address",
          name: "",
          type: "address",
        },
      ],
      name: "userTvlCap",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        {
          internalType: "uint256",
          name: "assets",
          type: "uint256",
        },
        {
          internalType: "address",
          name: "receiver",
          type: "address",
        },
        {
          internalType: "address",
          name: "owner",
          type: "address",
        },
      ],
      name: "withdraw",
      outputs: [
        {
          internalType: "uint256",
          name: "",
          type: "uint256",
        },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
  ];

  const [rangeStatistics, setRangeStatistics] = useState<any>();
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  const [vaultShare, setVaultShare] = useState(0);
  const overview = useRef(null);
  const lpTokenDetails = useRef(null);
  const strategy = useRef(null);
  const risk = useRef(null);
  const portfolioManager = useRef(null);
  const fees = useRef(null);
  const transactions = useRef(null);
  const depositors = useRef(null);
  const fAQ = useRef(null);

  const toast = useRef<Toast>(null);
  const [deatils, setDeatils] = useState({
    vaultName: "",
    assetName: "",
    assetImg: "",
    assetImg2: "",
    tvl: "",
    tvlInusd: "",
    holding: "",
    networkImg: "",
    buyToken: "",
    fundManger: "",
    vaultReturn: "",
    vaultOwner: "",
  });
  const [transaction, setTransaction] = useState([
    {
      date: "",
      type: "",
      txHash: "",
      txfee: "",
      value: "",
    },
  ]);
  const [transactionType, setTransactionType] = useState("");
  const [isWhiteListed, setWhiteListed] = useState(false);
  const [maxLimit, setMaxLimit] = useState<any>(null);
  const [valutJsonData, setValutJsonData] = useState<any>();
  const [latestBlockNumber, setLatestBlockNumber] = useState(0);
  const [tvlCap, setTvlCal] = useState("");
  const [userTvlCap, setUserTvlCap] = useState("");

  const [tvlCapInUsd, setTvlCalInUsd] = useState("");
  const [userTvlCapInUsd, setUserTvlCapInUsd] = useState("");

  const [depositerNumber, setDepositerNumber] = useState("");

  const [userApy, setUserApy] = useState("");
  const [vaultApy, setVaultApy] = useState<any>(null);
  const [holding, setHolding] = useState("");
  const [userShare, setUserShare] = useState("0");
  const [userShareInUsd, setUserShareInUsd] = useState("");
  const [useroverallReturn, setUserOverallReturn] = useState("");
  const [isApproved, setisApproved] = useState(false);
  const [depositAmout, setdepositAmout] = useState<any>("0");
  const [withdrawAmout, setwithdrawAmout] = useState("0");
  const provider = useProvider();
  const { data: signer } = useSigner();
  const { address, isConnected } = useAccount();
  const [products, setProducts] = useState<Product[]>([]);
  let { vaultAddress } = useParams();
  const [loading, setLoading] = useState(false);
  const [isTransactionOnGoing, setIsTransactionOnGoing] = useState(false);
  const balance = useBalance({
    address: "0x8734110e5e1dcF439c7F549db740E546fea82d66",
  });
  const switchNetwork = useSwitchNetwork();
  const { chain } = useNetwork();

  const [walletBalance, setWalletBalance] = useState<any>(null);
  const [assetAbiVal, setAssetAbi] = useState(null);
  const [approvalWaitingVisible, setapprovalWaitingVisible] =
    useState<boolean>(false);
  const [approvalSuccessfullVisible, setapprovalSuccessfullVisible] =
    useState<boolean>(false);
  const [openMetamaskVisible, setOpenMetamaskVisible] =
    useState<boolean>(false);
  const [depositWaitingVisible, setdepositWaitingVisible] =
    useState<boolean>(false);
  const [depositSuccessfullVisible, setdepositSuccessfullVisible] =
    useState<boolean>(false);

  const [graphOption, setGraphOption] = useState<any>();
  const [graphData, setgraphData] = useState<any>();

  useEffect(() => {
    setIsDataLoadng(true);
    getAllDetails();
    // getHarvestingStatisticsDetails();
    //getHistoricalData();
    (async () => {
      const response = await fetch("/vaultDetails.json");
      const valutData = await response.json();
      setValutJsonData(valutData[vaultAddress as string]);
      //setLoading(false);
      if (address) {
        const response = await fetch(
          valutData[vaultAddress as string].assetAbi
        );
        const data = await response.json();
        setAssetAbi(data?.abi);
        assetValueUpdate(data?.abi);
        checkAllowance();
      }
    })();
  }, [address, signer]);

  const getHarvestingStatisticsDetails = async () => {
    const response = await fetch("/vaultDetails.json");
    const valutData = await response.json();
    const strategyValut = valutData[vaultAddress as string];
    //if (signer) {

    let localProvider = new ethers.providers.JsonRpcProvider(
      strategyValut.rpcUrl
    );
    const contract = getContract(
      strategyValut["strategyAddress"] as string,
      strategyContractAbi,
      localProvider
    );

    const nonfungiblePositionManager =
      await contract.NonfungiblePositionManager();
    const tokenID = await contract.tokenID();

    const nonfungibleContract = getContract(
      nonfungiblePositionManager as string,
      nonfungibleContractAbi,
      localProvider
    );
    const nonfungibleDetails = await nonfungibleContract.positions(tokenID);
    console.log("nonfungibleDetails", nonfungibleDetails);

    const assetsDecimal = await fetch("/assetDecimal.json");
    const assetsDecimalJson = await assetsDecimal.json();

    const token0DecimalAndName = assetsDecimalJson[nonfungibleDetails.token0];
    const token1DecimalAndName = assetsDecimalJson[nonfungibleDetails.token1];

    const tokens0Val = ethers.utils.formatUnits(
      nonfungibleDetails.tokensOwed0,
      token0DecimalAndName.decimal
    );
    const tokens1Val = ethers.utils.formatUnits(
      nonfungibleDetails.tokensOwed1,
      token1DecimalAndName.decimal
    );

    const stakeAddress = await contract.stake();

    //call stake contract
    const stakecontract = getContract(
      stakeAddress as string,
      stakeContractAbi,
      localProvider
    );
    const stakecontractDetails = await stakecontract.slot0();

    const currentPrice = Math.pow(1.0001, stakecontractDetails.tick);
    const lowerRangePrice = Math.pow(1.0001, nonfungibleDetails.tickLower);
    const upperRangePrice = Math.pow(1.0001, nonfungibleDetails.tickUpper);
    const currentAllocation = getCurrentAllocation(
      lowerRangePrice,
      upperRangePrice,
      currentPrice
    );
    const token0Allocation = 100 / (1 + currentAllocation);
    const token1Allocation = 100 - 100 / (1 + currentAllocation);

    //get basic details
    const vaultContract = getContract(
      strategyValut.vaultAddress as string,
      RiveraAutoCompoundingVaultV2WhitelistedJson.abi,
      localProvider
    );

    let asset = await vaultContract.asset();
    const tokenName = await vaultContract.name();
    const vaultName = strategyValut.displayName;
    let totalAssets = await vaultContract.totalAssets();
    totalAssets = ethers.utils.formatUnits(
      totalAssets,
      assetsDecimalJson[asset as string].decimal
    );
    let totalSupply = await vaultContract.totalSupply();
    totalSupply = ethers.utils.formatUnits(
      totalSupply,
      assetsDecimalJson[asset as string].decimal
    );
    const vaultType = strategyValut.strategyType;
    let vaultShare = totalAssets / totalSupply;
    const strategylp = await vaultContract.strategy();

    const rangeStatisticsVal = {
      token0Name: token0DecimalAndName.name,
      token1Name: token1DecimalAndName.name,
      tokens0Val: tokens0Val,
      tokens1Val: tokens1Val,
      tickLower: nonfungibleDetails.tickLower,
      tickUpper: nonfungibleDetails.tickUpper,
      currentTick: stakecontractDetails.tick,
      currentPrice: currentPrice,
      lowerRange: nonfungibleDetails.tickLower,
      upperRange: nonfungibleDetails.tickUpper,
      lowerRangePrice: lowerRangePrice,
      upperRangePrice: upperRangePrice,
      currentAllocation: currentAllocation,
      token0Allocation: token0Allocation,
      token1Allocation: token1Allocation,
      tokenName: tokenName,
      vaultName: vaultName,
      totalSupply: Number(totalSupply).toFixed(2),
      strategy: strategylp,
      vaultType: vaultType,
      vaultShare: vaultShare,
      //"assetsName": assetsName
    };

    setRangeStatistics(rangeStatisticsVal);

    //}
  };

  const getCurrentAllocation = (a: number, b: number, k: number): number => {
    const numerator = Math.sqrt(b / k) * (Math.sqrt(k) - Math.sqrt(a));
    const denominator = Math.sqrt(b) - Math.sqrt(k);
    return numerator / denominator;
  };

  const getHistoricalData = async () => {
    const response = await fetch("/vaultDetails.json");
    const data = await response.json();
    const valutJsonData = data[vaultAddress as string];

    if (valutJsonData.id !== "169") {
      let headers = new Headers();
      headers.set("Authorization", "Bearer cqt_rQY6pwwVJ8jYkYkDKfW4qQY9PTFH");

      fetch(
        `https://api.covalenthq.com/v1/mantle-mainnet/address/${valutJsonData.vaultAddress}/transactions_v3/?quote-currency=USD`,
        { method: "GET", headers: headers }
      )
        .then((resp) => resp.json())
        .then((data) => {
          const output = data.data.items.slice(0, 10).map((e: any) => {
            const time = e.block_signed_at;
            const txhash = e.tx_hash;
            const txfee = e.fees_paid;
            let type = "";
            let value = "";
            if (e.log_events) {
              type = e.log_events[e.log_events.length - 1].decoded.name;
              if (
                e.log_events[e.log_events.length - 1].decoded.name ===
                  "Withdraw" ||
                e.log_events[e.log_events.length - 1].decoded.name ===
                  "Approval"
              ) {
                const prsVal =
                  e.log_events[e.log_events.length - 1].decoded.params;
                value = ethers.utils.formatEther(
                  prsVal[prsVal.length - 1].value
                );
              } else if (
                e.log_events[e.log_events.length - 1].decoded.name === "Deposit"
              ) {
                const prsVal = e.log_events[0].decoded.params;
                value = ethers.utils.formatEther(
                  prsVal[prsVal.length - 1].value
                );
              }
            }

            const returnVal = {
              date: time,
              type: type,
              txHash: txhash,
              txfee: ethers.utils.formatEther(txfee),
              value: value,
            };
            return returnVal;
          });
          setTransaction(output);
        });
    }
  };

  const getFirstBlockTimeStamp = async () => {
    const data = await fetch(
      "https://api.covalenthq.com/v1/mantle-mainnet/bulk/transactions/0x52f059A19291775f0BbC91cDca5A6c5dDFBb6ddE/?key=cqt_rQY6pwwVJ8jYkYkDKfW4qQY9PTFH"
    );
    const jsonData = await data.json();
    localStorage.setItem(
      "firstBlockTimeStamp",
      jsonData?.data?.items[0]?.block_signed_at
    );
  };

  const getAllDetails = async () => {
    const response = await fetch("/vaultDetails.json");
    const data = await response.json();
    const valutJsonData = data[vaultAddress as string];

    const detailsValFirst = {
      vaultName: valutJsonData?.displayName,
      assetName: valutJsonData?.denominationAsset,
      assetImg: valutJsonData?.assetImg,
      assetImg2: valutJsonData?.assetImg2,
      tvl: "",
      tvlInusd: "",
      holding: "",
      networkImg: valutJsonData?.chainImg,
      buyToken: valutJsonData?.buyToken,
      fundManger: "",
      vaultReturn: "",
      vaultOwner: "",
    };
    setDeatils(detailsValFirst);

    let localProvider;
    let vaultContract;

    localProvider = new ethers.providers.JsonRpcProvider(valutJsonData.rpcUrl);
    vaultContract = getContract(
      valutJsonData.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      localProvider
    );

    const asset = await vaultContract.asset(); //it will return the name of the asset of the valut

    const vaultShare = await vaultContract.convertToAssets(1000);

    setVaultShare(Number(vaultShare.toString()) / 1000);

    const assetsDecimal = await fetch("/assetDecimal.json");
    const assetsDecimalJson = await assetsDecimal.json();
    const assetsDecimalJsonDecimal = assetsDecimalJson[asset as string];

    const strategy = await vaultContract.strategy(); //it will return the name of the asset of the valut

    let strategyContract = getContract(
      strategy as string,
      cakeLpStakingV2Json.abi,
      localProvider
    );
    const manager = await strategyContract.manager();

    //get asset current price
    const convertedPrice = await getPriceInUsd(asset);

    let totalAssets = await vaultContract.totalAssets(); //it will return the total assets of the valut

    totalAssets = ethers.utils.formatUnits(
      totalAssets,
      assetsDecimalJsonDecimal.decimal
    );

    //calculate tvl and tvl inusd
    const tvl = totalAssets;
    const tvlInUsd = new Decimal(tvl).mul(
      new Decimal(convertedPrice.toString())
    ); //calculation with decimal js

    const owner = await vaultContract.owner();

    let totalSupply = await vaultContract.totalSupply();
    totalSupply = ethers.utils.formatUnits(
      totalSupply,
      assetsDecimalJsonDecimal.decimal
    );

    let firstBlockTimeStamp: any = "";

    if (localStorage.getItem("firstBlockTimeStamp")) {
      firstBlockTimeStamp = localStorage.getItem("firstBlockTimeStamp");
    } else {
      getFirstBlockTimeStamp();
      firstBlockTimeStamp = localStorage.getItem("firstBlockTimeStamp");
    }

    var d1 = new Date(firstBlockTimeStamp);
    var d2 = new Date();

    var diff = d2.getTime() - d1.getTime();
    var daydiff = diff / (1000 * 60 * 60 * 24);

    const currentShareValurInMnt = new Decimal(totalAssets).div(
      new Decimal(totalSupply)
    );
    const finalVal2 = Number(currentShareValurInMnt);

    const graphTimeArray = [d1, d2];
    const graphValArray = [1, finalVal2];

    //graphInitialization(graphTimeArray, graphValArray);

    if (valutJsonData?.networkName === "mantle") {
      // const poolData = await fetch(`https://api.geckoterminal.com/api/v2/networks/mantle/pools/multi/${valutJsonData?.pollContract}`);
      // const jsonPoolData = await poolData.json();

      // const finalTvlJsonData = [...jsonPoolData['data']];

      // const foundElement = finalTvlJsonData.find((element: any) => element['attributes'].address.toLowerCase() == valutJsonData.pollContract.toLowerCase());

      // const lpApr = 240.9 * (Number(foundElement.attributes.volume_usd.h24)) * Number(valutJsonData.feeTier) / Number(foundElement.attributes.reserve_in_usd);

      setVaultApy(0);
    } else {
      //get tvl value
      // const poolData = await fetch(`https://api.geckoterminal.com/api/v2/networks/${valutJsonData?.networkName}/pools/${valutJsonData?.pollContract}`);
      // const jsonPoolData = await poolData.json();
      // const finalTvlJsonData = jsonPoolData.data;

      // //const foundElement = finalTvlJsonData.element['attributes'].address.toLowerCase() == valutJsonData.pollContract.toLowerCase();

      // const lpApr = 240.9 * (Number(finalTvlJsonData.attributes.volume_usd.h24)) * Number(valutJsonData.feeTier) / Number(finalTvlJsonData.attributes.reserve_in_usd);

      setVaultApy(0);
    }

    const detailsVal = {
      vaultName: valutJsonData?.displayName,
      assetName: valutJsonData?.denominationAsset,
      assetImg: valutJsonData?.assetImg,
      assetImg2: valutJsonData?.assetImg2,
      tvl: Number(tvl).toFixed(2),
      tvlInusd: tvlInUsd.toFixed(18),
      holding: "",
      networkImg: valutJsonData?.chainImg,
      buyToken: valutJsonData?.buyToken,
      fundManger: owner,
      vaultReturn: finalVal2.toFixed(2),
      vaultOwner: manager,
    };
    setDeatils(detailsVal);

    if (address) {
      setWhiteListed(true);

      let tvlCap = await vaultContract.totalTvlCap();
      tvlCap = ethers.utils.formatUnits(
        tvlCap,
        assetsDecimalJsonDecimal.decimal
      );
      setTvlCal(tvlCap);

      const tvlCapInUsdval = new Decimal(tvlCap)
        .mul(new Decimal(convertedPrice.toString()))
        .toFixed(18); //calculation wth decimal js
      setTvlCalInUsd(tvlCapInUsdval);

      let share = await vaultContract.balanceOf(address);
      let userShareVal = await vaultContract.convertToAssets(share);

      setUserShare(
        ethers.utils.formatUnits(userShareVal, assetsDecimalJsonDecimal.decimal)
      );
    } else {
      let tvlCap = await vaultContract.totalTvlCap();
      tvlCap = ethers.utils.formatUnits(
        tvlCap,
        assetsDecimalJsonDecimal.decimal
      );
      setTvlCal(tvlCap);

      const tvlCapInUsdval = new Decimal(tvlCap)
        .mul(new Decimal(convertedPrice.toString()))
        .toFixed(18); //calculation wth decimal js
      setTvlCalInUsd(tvlCapInUsdval);
    }
    setIsDataLoadng(false);
  };

  const assetValueUpdate = async (abi?: any) => {
    const response = await fetch("/vaultDetails.json");
    const data = await response.json();
    const valutChainDetails = data[vaultAddress as string];

    let localProvider;
    let vaultContract;
    localProvider = new ethers.providers.JsonRpcProvider(
      valutChainDetails.rpcUrl
    );
    vaultContract = getContract(
      valutChainDetails.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      localProvider
    );

    const valutAssetAdress = await vaultContract.asset();

    const assetsDecimal = await fetch("/assetDecimal.json");
    const assetsDecimalJson = await assetsDecimal.json();
    const assetsDecimalJsonDecimal =
      assetsDecimalJson[valutAssetAdress as string];

    if (abi) {
      const asstsContract = getContract(valutAssetAdress, abi, localProvider);
      const balance = await asstsContract.balanceOf(address);
      setWalletBalance(
        ethers.utils.formatUnits(balance, assetsDecimalJsonDecimal.decimal)
      );
    } else {
      const asstsContract = getContract(
        valutAssetAdress,
        assetAbiVal,
        localProvider
      );
      const balance = await asstsContract.balanceOf(address);
      setWalletBalance(
        ethers.utils.formatUnits(balance, assetsDecimalJsonDecimal.decimal)
      );
    }
  };

  const checkAllowance = async () => {
    const response = await fetch("/vaultDetails.json");
    const data = await response.json();
    const valutChainDetails = data[vaultAddress as string];

    let localProvider;
    let vaultContract;
    // if (valutChainId.id === "56") {
    //   localProvider = new ethers.providers.JsonRpcProvider(valutChainId);
    //   vaultContract = getContract(vaultAddress as string, riveraAutoCompoundingVaultV2WhitelistedJson.abi, localProvider);
    // } else {
    //   localProvider = new ethers.providers.JsonRpcProvider(mantleRPCUrl);
    //   vaultContract = getContract(vaultAddress as string, riveraAutoCompoundingVaultV2WhitelistedJson.abi, localProvider);
    // }
    localProvider = new ethers.providers.JsonRpcProvider(
      valutChainDetails.rpcUrl
    );
    vaultContract = getContract(
      valutChainDetails.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      localProvider
    );

    const valutAssetAdress = await vaultContract.asset();

    // const asstsContract = getContract(valutAssetAdress, assetAbiVal, localProvider);
    // const balance = await asstsContract.balanceOf(address);
    // setWalletBalance(Number(ethers.utils.formatEther(balance)));

    const erc20Contract = getContract(
      valutAssetAdress,
      erc20Json.abi,
      localProvider
    );
    const allowance = await erc20Contract.allowance(
      address,
      valutChainDetails.vaultAddress
    ); //address:- login user address  //assetAdress:-valut asset address

    const assetsDecimal = await fetch("/assetDecimal.json");
    const assetsDecimalJson = await assetsDecimal.json();
    const assetsDecimalJsonDecimal =
      assetsDecimalJson[valutAssetAdress as string];

    setMaxLimit(
      ethers.utils.formatUnits(allowance, assetsDecimalJsonDecimal.decimal)
    );
    const maxLimitVal = new Decimal(
      ethers.utils.formatUnits(allowance, assetsDecimalJsonDecimal.decimal)
    );
    const despositAmtVal = new Decimal(depositAmout);

    if (despositAmtVal.gt(maxLimitVal)) {
      setisApproved(false);
      return false;
    } else {
      setisApproved(true);
      return true;
    }
  };

  const approveIntilize = async () => {
    const response = await fetch("/vaultDetails.json");
    const data = await response.json();
    const valutChainDetails = data[vaultAddress as string];

    const contract = getContract(
      valutChainDetails.vaultAddress as string,
      riveraAutoCompoundingVaultV2WhitelistedJson.abi,
      signer
    );
    const assetAdress = await contract.asset();
    await approve(assetAdress);
  };

  async function approve(assetsAddress: string) {
    const contract = getContract(assetsAddress, erc20Json.abi, signer);
    try {
      // const amount = 500e18;
      // let convertedAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 18 });
      // convertedAmount = convertedAmount.replace(/,/g, '');

      //showWarn("Please wait", "Transaction is in progress");

      const response = await fetch("/vaultDetails.json");
      const data = await response.json();
      const valutChainDetails = data[vaultAddress as string];

      const assetsDecimal = await fetch("/assetDecimal.json");
      const assetsDecimalJson = await assetsDecimal.json();
      const assetsDecimalJsonDecimal =
        assetsDecimalJson[assetsAddress as string];

      setIsTransactionOnGoing(true);

      const aa = Decimal.pow(10, assetsDecimalJsonDecimal.decimal);
      const bb = new Decimal(depositAmout);

      const convertedAmount = bb.mul(aa).toFixed();

      const aprvTxt = await contract.approve(
        valutChainDetails.vaultAddress,
        convertedAmount
      );
      setapprovalWaitingVisible(true);
      await aprvTxt
        .wait()
        .then(async (e: any) => {
          //showSuccess("Approval Confirmed");
          setapprovalWaitingVisible(false);
          setapprovalSuccessfullVisible(true);
          //setOpenMetamaskVisible(true);
          const isApproveVal = await checkAllowance();
          //setapprovalSuccessfullVisible(false);
          //setOpenMetamaskVisible(true);
          await deposit(isApproveVal);

          // setTimeout(() => {
          //   const despositAmtVal = new Decimal(depositAmout);
          //   const maxLimitVal = new Decimal(maxLimit);
          //   if (maxLimitVal.gt(despositAmtVal)) {
          //     setisApproved(true);
          //   } else {
          //     setisApproved(false);
          //   }
          // }, 2000);

          setIsTransactionOnGoing(false);
        })
        .catch((error: any) => {
          setIsTransactionOnGoing(false);
          alert(error);
        });
    } catch (err: any) {
      const message = "Some Error Occured While Approving";
      showWarn("Warning", message);
    }
  }

  const deposit = async (isApprovedVal?: boolean) => {
    setTransactionType("Deposit");
    const despositAmtVal = new Decimal(depositAmout);
    const walletBalanceVal = new Decimal(walletBalance);

    if (isApprovedVal !== undefined && !isApprovedVal) {
      const message =
        "Approved amount is lower than Deposit amount. Please try again.";
      showWarn("Warning", message);
      return;
    }

    if (despositAmtVal.lt(new Decimal("0.0001"))) {
      const message = "Min Limit 0.0001";
      showWarn("Warning", message);
      return;
    }

    if (despositAmtVal.gt(walletBalanceVal)) {
      const message = "Insufficient Balance";
      showWarn("Warning", message);
      return;
    }

    if (Number(deatils.tvl) + Number(depositAmout) > Number(tvlCap)) {
      showWarn("Warning", "Vault Capacity Reached");
      return;
    }
    //setapprovalSuccessfullVisible(false);
    setIsTransactionOnGoing(true);

    const response = await fetch("/vaultDetails.json");
    const data = await response.json();
    const valutChainDetails = data[vaultAddress as string];

    const contract = getContract(
      valutChainDetails.vaultAddress as string,
      vaultAbi,
      signer
    );
    // const amount = +depositAmout * Math.pow(10, 18);
    // let convertedAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 18 });
    // convertedAmount = convertedAmount.replace(/,/g, '');

    const valutAssetAdress = await contract.asset();

    const assetsDecimal = await fetch("/assetDecimal.json");
    const assetsDecimalJson = await assetsDecimal.json();
    const assetsDecimalJsonDecimal =
      assetsDecimalJson[valutAssetAdress as string];

    const aa = Decimal.pow(10, assetsDecimalJsonDecimal.decimal);
    const bb = new Decimal(depositAmout);

    const convertedAmount = bb.mul(aa).toFixed();
    //calling the deposit method
    const aprvTxt = await contract.deposit(convertedAmount, address, {
      gasLimit: 8000000,
      // gasLimit: 10000000
    });
    //setLoading(true);
    //showWarn("Please wait", "Transaction is in progress");
    setapprovalSuccessfullVisible(false);
    setdepositWaitingVisible(true);
    await aprvTxt
      .wait()
      .then(async (e: any) => {
        setdepositWaitingVisible(false);

        setdepositSuccessfullVisible(true);
        //showSuccess("Transaction Confirmed");
        await checkAllowance();
        await assetValueUpdate();
        setIsTransactionOnGoing(false);
        await getAllDetails();
      })
      .catch((error: any) => {
        setLoading(false);
        setIsTransactionOnGoing(false);
        showError("Something went wrong");
      });
  };

  const withdraw = async () => {
    setTransactionType("Withdraw");
    const withdrawAmoutVal = new Decimal(withdrawAmout);
    const userHohlding = new Decimal(userShare);

    if (withdrawAmoutVal.lt(new Decimal("0.0001"))) {
      const message = "Min Limit 0.0001";
      showWarn("Warning", message);
      return;
    }

    if (withdrawAmoutVal.gt(userHohlding)) {
      const message = "Insufficient Holdings";
      showWarn("Warning", message);
      return;
    }

    const response = await fetch("/vaultDetails.json");
    const data = await response.json();
    const valutChainDetails = data[vaultAddress as string];

    const contract = getContract(
      valutChainDetails.vaultAddress as string,
      vaultAbi,
      signer
    );
    //convverting the deposit ampount to 10^18 format
    // const amount = withdrawAmout * Math.pow(10, 18);
    // let convertedAmount = amount.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 18 });
    // convertedAmount = convertedAmount.replace(/,/g, '');

    const valutAssetAdress = await contract.asset();

    const assetsDecimal = await fetch("/assetDecimal.json");
    const assetsDecimalJson = await assetsDecimal.json();
    const assetsDecimalJsonDecimal =
      assetsDecimalJson[valutAssetAdress as string];

    const aa = Decimal.pow(10, assetsDecimalJsonDecimal.decimal);
    const bb = new Decimal(withdrawAmout);

    const convertedAmount = bb.mul(aa).toFixed();

    //calling the deposit method
    //showWarn("Please wait", "Transaction is in progress");

    setIsTransactionOnGoing(true);
    const aprvTxt = await contract.withdraw(convertedAmount, address, address, {
      gasLimit: 8000000,
    });
    setdepositWaitingVisible(true);
    //setLoading(true);
    await aprvTxt
      .wait()
      .then(async (e: any) => {
        setdepositWaitingVisible(false);
        setdepositSuccessfullVisible(true);
        //showSuccess("Transaction Confirmed");
        await checkAllowance();
        await getAllDetails();
        setIsTransactionOnGoing(false);
        assetValueUpdate();
      })
      .catch((error: any) => {
        //setLoading(false);
        setIsTransactionOnGoing(false);
        showError("Something went wrong");
      });
  };

  const handledepositAmoutChange = (event: any) => {
    let val = event.target.value;
    if (val === "") {
      val = "0";
    }
    const enteredVal = new Decimal(val);
    const mxlmtVal = new Decimal(maxLimit);
    if (enteredVal.gt(mxlmtVal)) {
      setisApproved(false);
    } else {
      setisApproved(true);
    }
    setdepositAmout(event.target.value);
  };

  const updateDepositMax = () => {
    // if(maxLimit === 0 || walletBalance < maxLimit){
    //   setdepositAmout(removeLastDigit(walletBalance));
    // } else {
    //   setdepositAmout(removeLastDigit(maxLimit));
    // }
    setdepositAmout(walletBalance);
    const walletBalanceVal = new Decimal(walletBalance);
    const maxLimitVal = new Decimal(maxLimit);
    if (walletBalanceVal.gt(maxLimitVal)) {
      setisApproved(false);
    } else {
      setisApproved(true);
    }
  };

  const handlewithdrawAmoutChange = (event: any) => {
    setwithdrawAmout(event.target.value);
  };

  const updateWithdrawMax = () => {
    setwithdrawAmout(userShare);
  };

  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showWarn = (header: string, message: string) => {
    toast.current?.show({
      severity: "warn",
      summary: header,
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const fetchJsonData = async () => {
    try {
      const response = await fetch("/vaultDetails.json");
      const data = await response.json();
      setValutJsonData(data[vaultAddress as string]);
    } catch (error) {
      console.log("Error fetching JSON data:", error);
    }
  };

  const getContract = (address: string, abi: any, provider: any) => {
    return new ethers.Contract(address, abi, provider);
  };

  const networkSwitchHandler = (networkId: number) => {
    (switchNetwork as any).switchNetwork(networkId);
  };
  const getTwoDecimal = (num: any) => {
    // const num = 199.9989;
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  };

  const fnum = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }
    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  };
  async function getPriceInUsd(address: string): Promise<any> {
    if (address === "0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111") {
      const data = await fetch(
        "https://api.geckoterminal.com/api/v2/networks/mantle/pools/0xA125AF1A4704044501Fe12Ca9567eF1550E430e8"
      );
      const jsonData = await data.json();
      const price = jsonData["data"]["attributes"].base_token_price_usd;
      return new Promise((resolve, reject) => {
        resolve(Number(price));
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve(Number(1));
      });
    }
  }

  const goToUrl = (url: any) => {
    window.open(url, "_blank");
  };

  const gotoDiscord = () => {
    const url = "https://discord.com/invite/sbMxwS6VEV";
    window.open(url, "_blank");
  };

  function removeLastDigit(num: number): number {
    const strNum = num.toString();
    const decimalIndex = strNum.indexOf(".");
    if (decimalIndex === -1) {
      return num; // Return the number as is if it doesn't have a decimal part
    }
    const truncatedDecimal =
      strNum.slice(0, decimalIndex + 1) + strNum.slice(decimalIndex + 1, -1);
    return parseFloat(truncatedDecimal);
  }

  const copyClipBoard = (val: any) => {
    navigator.clipboard.writeText(val);
    showSuccess("Copied successfully");
  };

  interface CustomTabHeaderProps {
    icon: string;
    title: string;
  }

  const CustomTabHeader: React.FC<CustomTabHeaderProps> = ({ icon, title }) => {
    return (
      <>
        {icon && (
          <img
            src={icon}
            alt="Icon"
            style={{ marginRight: "10px", width: "28px" }}
          />
        )}
        <span>{title}</span>
      </>
    );
  };

  //chart js start

  const graphInitialization = (labels: any[], values: any[]) => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      TooltipChart,
      Legend
    );

    const options = {
      responsive: true,
      plugins: {
        legend: {
          position: "top" as const,
        },
        title: {
          display: true,
          text: "",
        },
      },
    };

    // const addDays = (date: Date, days: number): Date => {
    //   let result = new Date(date);
    //   result.setDate(result.getDate() + days);
    //   return result;
    // };

    //const labels = [new Date(), addDays(new Date(), 10)];
    // console.log("sample graph data", faker.datatype.number({ min: -1000, max: 1000 }));
    // console.log("sample graph data 2", labels?.map(() => faker.datatype.number({ min: -1000, max: 1000 })));
    const data = {
      labels,
      datasets: [
        {
          label: "Vault Share Price",
          data: values,
          borderColor: "rgba(145, 77, 229, 1)",
          backgroundColor: "rgba(145, 77, 229, 1)",
        },
        // {
        //   label: 'Dataset 2',
        //   data: labels?.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        //   borderColor: 'rgb(53, 162, 235)',
        //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
        // },
      ],
    };

    setGraphOption(options);
    setgraphData(data);
  };

  //chart js end

  // highlight section start

  // Get all sections that have an ID defined
  const sections = document.querySelectorAll("section[id]");
  // Add an event listener listening for scroll
  window.addEventListener("scroll", navHighlighter);

  function navHighlighter() {
    // Get current scroll position
    let scrollY = window.pageYOffset;

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach((current: any) => {
      const sectionHeight = current["offsetHeight"];
      const sectionTop =
        current.getBoundingClientRect().top + window.pageYOffset - 100;
      const sectionId = current.getAttribute("id");

      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        document.querySelector("#li_" + sectionId)?.classList.add("active");
      } else {
        document.querySelector("#li_" + sectionId)?.classList.remove("active");
      }
    });
  }

  const scrollToSection = (elementRef: any) => {
    window.scrollTo({
      top: elementRef.current.offsetTop - 70,
      behavior: "smooth",
    });
  };

  // highlight section end

  return (
    <>
      <Toast ref={toast} />
      {loading ? (
        <>
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        </>
      ) : (
        <>
          <div className="custom-container mt-4">
            <div className="row mob_clm_rvs">
              <div className="col-md-8">
                <div className="small-div-1"></div>
                <div className="first_section outer_section_detail notMobileDevice">
                  <div className="d_flex_frst">
                    <div className="header_font_size redHatFont">
                      <span>
                        <img
                          src={deatils?.assetImg}
                          alt="btc img"
                          className="btc_asst_img_width"
                        />
                      </span>
                      <span>
                        <img
                          src={deatils?.assetImg2}
                          alt="btc img"
                          className="btc_img_width"
                        />
                      </span>
                      {deatils?.vaultName}
                    </div>
                    <div className="chain_pos_det_v2">
                      <span>
                        <img src={deatils?.networkImg} alt="chain" />
                      </span>
                    </div>
                  </div>
                  <div className="dsp_flex_start dspWrap mt-3 mb-4">
                    {valutJsonData?.isLiquidityMining ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Liquidity Mining
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isActiveStrategy ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Active Strategy
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isWideRange ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Wide Range
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isPeggedStrategy ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Pegged Strategy
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isStaticRange ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Static Range
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isDynamicStrategy ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Dynamic
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isLSDFarming ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={LSDFarmingImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            LSD Farming
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isVolatilePair ? (
                      <>
                        {" "}
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={VolatilePairColorImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Volatile Pair
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isStablePair ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={StablePairColorImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Stable Pair
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="dsp mb-3 wdth_100">
                    <div className="mbl_mrgn_10">
                      LP APR <br />{" "}
                      <span className="holding_val">
                        {isDataLoadng ? (
                          <>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          </>
                        ) : (
                          <>{vaultApy?.toFixed(2)} %</>
                        )}
                      </span>
                    </div>
                    <div className="mbl_mrgn_10">
                      TVL <br />{" "}
                      <span className="fnt_wgt_600">
                        {fnum(Number(deatils.tvl))} {deatils?.assetName}
                      </span>
                    </div>
                    <div className="mbl_mrgn_10">
                      Yield Source <br />{" "}
                      <span>
                        <img
                          src={valutJsonData?.poweredByVault}
                          className="wdth_140"
                          alt="pancake"
                        />
                      </span>
                    </div>
                    {/* <div className='mbl_mrgn_10'>Hedged On <br />
                    <span><img src={valutJsonData?.lendedBy} className='wdth_140 mtLend-1' alt='pancake' /></span>
                  </div> */}
                    {/* {valutJsonData?.risk?.safetyScore ? <>
                    <div className='mbl_mrgn_10'>Safety Score <br /> <span className='holding_val'>{valutJsonData?.risk?.safetyScore} <img src={saftyImg} alt='safty img' className='sftyImgWdth' /></span></div>
                  </> : <></>} */}
                  </div>
                </div>
                <div className="second_section outer_section_detail nav_design ovrFlow">
                  <nav className="navigation">
                    <ul>
                      <li
                        id="li_overview"
                        onClick={() => scrollToSection(overview)}
                      >
                        Overview
                      </li>
                      <li
                        id="li_strategy"
                        onClick={() => scrollToSection(strategy)}
                      >
                        Strategy
                      </li>
                      {/* <li id='li_lpTokenDetails' onClick={() => scrollToSection(lpTokenDetails)}>LP Token</li> */}
                      <li id="li_risk" onClick={() => scrollToSection(risk)}>
                        Risk
                      </li>
                      <li
                        id="li_portfolioManager"
                        onClick={() => scrollToSection(portfolioManager)}
                      >
                        Portfolio Manager
                      </li>
                      <li id="li_fees" onClick={() => scrollToSection(fees)}>
                        Fees
                      </li>
                      {/* {(valutJsonData?.id !== "169") ? <> <li id='li_transactions' onClick={() => scrollToSection(transactions)}>Transactions</li></> : <></>} */}
                      <li id="li_fAQ" onClick={() => scrollToSection(fAQ)}>
                        FAQ
                      </li>
                    </ul>
                  </nav>
                </div>
                <section id="overview" ref={overview}>
                  <div className="third_section outer_section_detail">
                    <div className="hdr_txt mb-3 redHatFont">Overview</div>
                    <div className="dsp_cont">
                      <div className="brdr_blck pdng_box mb-2">
                        TVL{" "}
                        <div className="mt-2 fnt_wgt_600">
                          {fnum(Number(deatils.tvl))} {deatils?.assetName}
                        </div>
                      </div>
                      <div className="brdr_blck pdng_box mb-2">
                        LP APR{" "}
                        <div className="mt-2 txt_clr_grn">
                          {isDataLoadng ? (
                            <>
                              <Skeleton
                                width="5rem"
                                className="mb-2"
                              ></Skeleton>
                            </>
                          ) : (
                            <>{vaultApy?.toFixed(2)} %</>
                          )}
                        </div>
                      </div>
                      <div className="brdr_blck pdng_box mb-2">
                        Denomination Asset{" "}
                        <div className="mt-2 fnt_wgt_600">
                          {" "}
                          <img
                            src={deatils.assetImg}
                            alt="btc img"
                            className="wdth_28"
                          />{" "}
                          {deatils.assetName}
                        </div>
                      </div>

                      <div className="brdr_blck pdng_box mb-2">
                        Vault Contract{" "}
                        <div className="mt-2 fnt_wgt_600">
                          {isDataLoadng ? (
                            <>
                              <Skeleton
                                width="5rem"
                                className="mb-2"
                              ></Skeleton>
                            </>
                          ) : (
                            <>
                              {/* {vaultShare} {deatils?.assetName} */}
                              <span>
                                <a
                                  href={`${valutJsonData?.explorerLink}/address/${valutJsonData?.vaultAddress}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  View on Explorer
                                </a>
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <div className='mt-3 notMobileDevice'>
                    {graphOption && graphData ? <Line options={graphOption} data={graphData} /> : <></>}
                  </div> */}
                  </div>
                </section>
                <section id="strategy" ref={strategy}>
                  <div className="third_section outer_section_detail">
                    <div className="hdr_txt mb-2 redHatFont">Strategy</div>
                    <div className="fnt_wgt_600 mb-2 redHatFont">Summary</div>
                    <span>
                      <ul>
                        {valutJsonData?.strategy?.val.map(
                          (data: any, index: any) => {
                            return (
                              <>
                                <li key={index} className="mb-2">
                                  {data}
                                </li>
                              </>
                            );
                          }
                        )}
                      </ul>
                    </span>

                    {valutJsonData?.protocols.map((data: any) => {
                      return (
                        <>
                          <div className="backGrd mb-3">
                            <div className="dsp mb-2">
                              <div className="fnt_wgt_600 mb-3">
                                <img
                                  src={data?.logo}
                                  className="wdth_140"
                                  alt="pancake"
                                />
                              </div>
                              <div className="d-flex mb-3">
                                <div
                                  className="crsr_pntr"
                                  onClick={() => {
                                    goToUrl(data.website);
                                  }}
                                >
                                  <span className="westBtn">
                                    <img src={globeImg} alt="website" /> Website
                                  </span>
                                </div>
                                <div
                                  className="crsr_pntr"
                                  onClick={() => {
                                    goToUrl(data.contract);
                                  }}
                                >
                                  <span className="westBtn">
                                    <img src={arrowUpImg} alt="website" />{" "}
                                    Contract
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="fnt_14 mt-3">{data.details}</div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </section>

                {/* <section id='lpTokenDetails' ref={lpTokenDetails}>
                <div className='third_section outer_section_detail'>
                  <div className='hdr_txt mb-2 redHatFont'>LP Token</div>

                  <div className='dsp mb-2 mt-3'>
                    <div><div>LP Token Name</div><div className='fnt_wgt_600 redHatFon'>{rangeStatistics?.tokenName}</div></div>
                    <div><div>Current Supply</div><div className='fnt_wgt_600 redHatFon'> {rangeStatistics?.totalSupply} {rangeStatistics?.tokenName}</div></div>
                    <div><div>LP Price</div><div className='fnt_wgt_600 redHatFon'>{Number(rangeStatistics?.vaultShare).toFixed(6)} {valutJsonData?.denominationAsset}</div></div>
                  </div>

                  <div className='dsp'>

                    <div><div>Strategy Address</div><div className='fnt_wgt_600 redHatFon'>{rangeStatistics?.strategy}
                      <span className='ml_10'><a href={`${valutJsonData?.explorerLink}/address/${rangeStatistics?.strategy}`} target="_blank" rel="noreferrer"><img src={eternalLinkImg} alt='external link img' /></a></span></div></div>
                    <div><div>Strategy Type</div><div className='fnt_wgt_600 redHatFon'> {rangeStatistics?.vaultType}</div></div>
                    <div></div>
                  </div>

                </div>
              </section> */}

                <section id="risk" ref={risk}>
                  <div className="fourth_section outer_section_detail">
                    <span className="hdr_txt mb-2 redHatFont">Risk</span>

                    {/* {valutJsonData?.risk?.safetyScore ? (
                      <>
                        <div className="backGrd mb-3 mt-3">
                          <div className="mb-2">
                            <div className="opt_67">Safety Score </div>{" "}
                            <span className="holding_val">
                              {valutJsonData?.risk?.safetyScore}{" "}
                              <img
                                src={saftyImg}
                                alt="safty img"
                                className="sftyImgWdth"
                              />
                            </span>
                          </div>

                          {valutJsonData?.risk?.safetyScorePoint.map(
                            (data: any) => {
                              return (
                                <>
                                  <div className="mb-2">
                                    {data.side === "up" ? (
                                      <img src={upImg} alt="up" />
                                    ) : (
                                      <img src={downImg} alt="down" />
                                    )}
                                    <span className="opt_67 mrgn_18">
                                      {data.details}{" "}
                                    </span>
                                    <br />
                                    <span className="rvr_sty">{data.type}</span>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )} */}

                    <div className="fnt_wgt_600 mb-3 font_18 redHatFont">
                      Risks
                    </div>
                    <div>
                      <ul className="pdng_18">
                        {valutJsonData?.risk?.riskType.map((data: any) => {
                          return (
                            <>
                              <li className="mrgn_btm_15">
                                <span className="fnt_wgt_600">
                                  {data.details}:
                                </span>{" "}
                                {data.type}
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </section>
                <section id="portfolioManager" ref={portfolioManager}>
                  <div className="fifth_section outer_section_detail">
                    <div className="hdr_txt mb-2 redHatFont">
                      Portfolio Manager
                    </div>
                    <div className="mt-4">
                      <span className="fnt_wgt_600">Vault owner</span>
                      <div className="dsp portfolio_wdth_55 prtfol_back mt-2 mb-3">
                        <div className="fnt_14 overflowWrap">
                          {deatils?.vaultOwner}
                          <span className="ml_10">
                            <a
                              href={`${valutJsonData?.explorerLink}/address/${deatils?.vaultOwner}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={eternalLinkImg}
                                alt="external link img"
                              />
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="fnt_wgt_600">Fund Manager</span>
                      <div className="dsp portfolio_wdth_55 prtfol_back mt-2 mb-3">
                        <div className="fnt_14 overflowWrap">
                          {deatils?.fundManger}
                          <span className="ml_10">
                            <a
                              href={`${valutJsonData?.explorerLink}/address/${deatils?.fundManger}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={eternalLinkImg}
                                alt="external link img"
                              />
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="fees" ref={fees}>
                  <div className="sixth_section outer_section_detail">
                    <div className="hdr_txt mb-2 redHatFont">Fees</div>
                    <div>
                      <table className="table">
                        <thead>
                          <tr>
                            <th scope="col">Fee type</th>
                            <th scope="col">Settings</th>
                            <th scope="col">Recipient</th>
                          </tr>
                        </thead>
                        <tbody>
                          {valutJsonData?.fees.map((data: any) => {
                            return (
                              <>
                                <tr>
                                  <td>{data.feeType}</td>
                                  <td>
                                    {data.settings.name} <br />{" "}
                                    {data.settings.value}
                                  </td>
                                  <td>
                                    {data.recipent.name}
                                    <br />
                                    {data.recipent.value}
                                    {/* <span className='mrgn_5'>
                                  <img src={eternalLinkImg} alt='external link img' /></span> <span ><img src={copyImg} alt='copy img' /></span> */}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                {/* {(valutJsonData?.id !== "169") ? <>
              <section id='transactions' ref={transactions}>
                <div className='sixth_section outer_section_detail'>
                  <div className='hdr_txt mb-2 redHatFont'>Transactions</div>
                  <div>
                    <table className="table">
                      <tbody>
                        {transaction.map((data: any) => {
                          return <>
                            <tr>
                              <td >
                                <span className='fnt_14'>{data.date}</span>
                                <a href={`https://explorer.testnet.mantle.xyz/tx/${data.txHash}`} target='_blank' rel="noreferrer"><img src={eternalLinkImg} className='trsnaCpyImg' alt='external link img' /></a>
                                <div className='fnt_wgt_600 mb-3'>{data.type}</div>

                              </td>
                              <td>Value <br /> Txn. fee</td>
                              <td>{fnum(Number(data.value))} {deatils?.assetName}<br />
                                {Number(data.txfee).toFixed(2)} MNT</td>
                            </tr>
                          </>
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
              </> : <></>} */}

                <section id="fAQ" ref={fAQ}>
                  <div className="sixth_section outer_section_detail mb-5">
                    <div className="hdr_txt mb-4 redHatFont">FAQ</div>
                    <div>
                      <Accordion activeIndex={0}>
                        <AccordionTab header="Is there any lock-up period for funds?">
                          <p className="m-0">
                            No, there is no lock-up period in this vault. You
                            can withdraw your funds at any time without any
                            restriction or penalty.
                          </p>
                        </AccordionTab>
                        <AccordionTab header="What assets are the yields paid in?">
                          <p className="m-0">
                            The yields are paid in the denomination asset of the
                            strategy and are compounded automatically.
                            Unharvested rewards can be temporarily held in the
                            form of farmed assets.
                          </p>
                        </AccordionTab>
                        <AccordionTab header="What are the fees associated with using the vault?">
                          <p className="m-0">
                            Rivera Vaults applies a performance fee that is
                            automatically claimed during vault compounding. This
                            means that all APR and portfolio balances you see in
                            our platform are already adjusted for any fees
                            charged. Selected vaults have an exit fee which will
                            be deducted during the withdrawal. All fees are
                            displayed in the deposit & withdrawal section for
                            full transparency.
                          </p>
                        </AccordionTab>
                        <AccordionTab header="I'm having trouble using the app. What should I do?">
                          <p className="m-0">
                            Join our Discord community and help us improve our
                            app by reporting your issue. Win exclusive rewards,
                            bounty & more for your contribution.
                          </p>
                        </AccordionTab>
                        <AccordionTab header="I love Rivera! How can I get involved?">
                          <p className="m-0">
                            We are recruiting DeFi pros who can help us redefine
                            the future of DeFi liquidity management. Join us in
                            testing the Rivera protocol and contributing to its
                            development. We are awarding exclusive OG NFTs to
                            the top 100 testers. Get started by joining our
                            Discord.
                          </p>
                        </AccordionTab>
                      </Accordion>
                    </div>
                  </div>
                </section>
              </div>
              <div className="col-md-4">
                <div className="small-div-2"></div>

                <div className="first_section outer_section_detail mobileDevice">
                  <div className="d_flex_frst">
                    <div className="header_font_size redHatFont">
                      <span>
                        <img
                          src={deatils?.assetImg}
                          alt="btc img"
                          className="btc_asst_img_width"
                        />
                      </span>
                      <span>
                        <img
                          src={deatils?.assetImg2}
                          alt="btc img"
                          className="btc_img_width"
                        />
                      </span>
                      {deatils?.vaultName}
                    </div>
                    <div className="chain_pos_det_v2">
                      <span>
                        <img src={deatils?.networkImg} alt="chain" />
                      </span>
                    </div>
                  </div>
                  <div className="dsp_flex_start dspWrap mt-3 mb-4">
                    {valutJsonData?.isLiquidityMining ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Liquidity Mining
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isActiveStrategy ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Active Strategy
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isWideRange ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={almImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Wide Range
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isPeggedStrategy ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Pegged Strategy
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isStaticRange ? (
                      <>
                        <div className="trdng_outer mr_12">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Static Range
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isDynamicStrategy ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={dynamicStrategyImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Dynamic
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isLSDFarming ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={LSDFarmingImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            LSD Farming
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isVolatilePair ? (
                      <>
                        {" "}
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={VolatilePairColorImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Volatile Pair
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {valutJsonData?.isStablePair ? (
                      <>
                        <div className="trdng_outer">
                          {" "}
                          <span className="trdng_width">
                            <img
                              src={StablePairColorImg}
                              className="ml_8"
                              alt="arrow img"
                            />
                            Stable Pair
                          </span>{" "}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="trdng_outer">
                      {valutJsonData?.valutType === "whitelisted" ? (
                        <>
                          <span className="wthlist_back">
                            <img
                              src={lockImg}
                              alt="lock img"
                              className="wthlist_back_img"
                            />
                            {valutJsonData?.valutType}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="dsp mb-3 wdth_100">
                    <div className="mbl_mrgn_10">
                      LP APR <br />{" "}
                      <span className="holding_val">
                        {vaultApy?.toFixed(2)} %
                      </span>
                    </div>
                    <div className="mbl_mrgn_10">
                      TVL <br />{" "}
                      <span className="fnt_wgt_600">
                        {fnum(Number(deatils.tvl))} {deatils?.assetName}
                      </span>
                    </div>
                    <div className="mbl_mrgn_10">
                      Yeild Source
                      <br />{" "}
                      <span>
                        <img
                          src={valutJsonData?.poweredByVault}
                          className="wdth_140"
                          alt="pancake"
                        />
                      </span>
                    </div>
                    {/* <div>Hedged On <br /><span><img src={valutJsonData?.lendedBy} className='wdth_140 mtLend-1' alt='pancake' /></span></div> */}
                    <div className="mbl_mrgn_10">
                      Safety Score <br />{" "}
                      <span className="holding_val">
                        {valutJsonData?.risk?.safetyScore}{" "}
                        <img
                          src={saftyImg}
                          alt="safty img"
                          className="sftyImgWdth"
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {address ? (
                  <>
                    <div className="first_section outer_section_detail">
                      <div className="dsp">
                        <div>
                          <div className="holding_header">
                            Your Holdings
                            <img
                              className="toolTipHolding ml_5"
                              src={infoCircleImg}
                              data-pr-tooltip="The displayed portfolio balance has already accounted for the vault performance fee."
                              alt="info"
                            />
                            <Tooltip
                              target=".toolTipHolding"
                              mouseTrack
                              mouseTrackLeft={10}
                            />
                          </div>
                          <div className="holding_header_inner mb-3 secondary_color">
                            {Number(userShare).toFixed(4)} {deatils?.assetName}
                          </div>
                        </div>
                        <div className="txtAlgnRight">
                          <img
                            src={dollarImg}
                            className="dllrImgwdth"
                            alt="dollar"
                          />
                        </div>
                      </div>

                      {/* <div className='dsp'>
                      <div>PnL <br /> <span className='holding_val'>-</span></div>
                      <div>Absolute Returns <br /> <span className='holding_val'>-</span></div>
                    </div> */}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="second_section outer_section_detail pos_sticky">
                  <TabView>
                    <TabPanel
                      header={
                        <CustomTabHeader icon={depositTabImg} title="Deposit" />
                      }
                    >
                      <div className="mt-3">
                        <div className="dsp">
                          <div>Deposits</div>
                          <div>
                            {fnum(Number(deatils.tvl))} {deatils?.assetName}
                          </div>
                        </div>
                        <div>
                          <ProgressBar
                            showValue={false}
                            value={Number(
                              (
                                (Number(deatils.tvlInusd) /
                                  Number(tvlCapInUsd)) *
                                100
                              ).toFixed(4)
                            )}
                          ></ProgressBar>
                        </div>
                        <div className="dsp mb-3">
                          <div>Capacity</div>
                          <div>
                            {fnum(Number(tvlCap))} {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp backGrd mb-3">
                          <div className="fnt_wgt_600 text-center">
                            <img
                              src={deatils?.assetImg}
                              className="wdth_30_px"
                              alt="usdt"
                            />{" "}
                            <br /> {deatils?.assetName}
                          </div>
                          <div>
                            <input
                              type="number"
                              id="first_name"
                              name="first_name"
                              value={depositAmout}
                              onChange={handledepositAmoutChange}
                            />
                            <button
                              className="btn mxBtn"
                              onClick={updateDepositMax}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="dsp">
                          <div>Wallet balance</div>
                          <div>
                            {Number(walletBalance).toFixed(2)}{" "}
                            {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp">
                          <div className="buy_cake mt-1 mb-2">
                            {" "}
                            <a
                              target="_blank"
                              href={deatils?.buyToken}
                              className="clr_prpl"
                              rel="noreferrer"
                            >
                              Buy ${deatils?.assetName}
                            </a>
                          </div>
                        </div>
                        <hr />
                        <div className="dsp">
                          <div>
                            Performance Fee
                            <img
                              className="toolTipPerformance ml_5"
                              src={infoCircleImg}
                              data-pr-tooltip="The displayed APY accounts for performance fee  that is deducted from the generated yield only."
                              alt="info"
                            />
                            <Tooltip
                              target=".toolTipPerformance"
                              mouseTrack
                              mouseTrackLeft={10}
                            />
                          </div>
                          <div>{valutJsonData?.fees[0].settings.value}</div>
                        </div>
                        <div className="dsp">
                          <div>
                            Exit Fee
                            <img
                              className="toolTipExit ml_5"
                              src={infoCircleImg}
                              data-pr-tooltip="Distributed among all remaining vault participants"
                              alt="info"
                            />
                            <Tooltip
                              target=".toolTipExit"
                              mouseTrack
                              mouseTrackLeft={10}
                            />
                          </div>
                          <div>{valutJsonData?.fees[1].settings.value}</div>
                        </div>
                        <div className="mt-3 text-center">
                          {isConnected ? (
                            (chain as any)?.id !== Number(valutJsonData?.id) ? (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={() => {
                                  networkSwitchHandler(
                                    Number(valutJsonData?.id)
                                  );
                                }}
                              >
                                Switch to {valutJsonData?.chainname}
                              </button>
                            ) : isApproved ? (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={() => {
                                  deposit();
                                }}
                                disabled={isTransactionOnGoing}
                              >
                                Deposit
                              </button>
                            ) : (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={approveIntilize}
                                disabled={isTransactionOnGoing}
                              >
                                Approve
                              </button>
                            )
                          ) : (
                            <>
                              <div className="wdth_100">
                                <ConnectBtn />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mt-3 text-center">
                          <div>
                            <img src={zapIMg} alt="dollar" />
                            <span>Zap in with ${deatils?.assetName}</span>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel
                      header={
                        <CustomTabHeader
                          icon={withdrwaTabImg}
                          title="Withdraw"
                        />
                      }
                    >
                      <div className="mt-3">
                        <div className="dsp">
                          <div>Deposits</div>
                          <div>
                            {fnum(Number(deatils.tvl))} {deatils?.assetName}
                          </div>
                        </div>
                        <div>
                          <ProgressBar
                            showValue={false}
                            value={Number(
                              (
                                (Number(deatils.tvlInusd) /
                                  Number(tvlCapInUsd)) *
                                100
                              ).toFixed(4)
                            )}
                          ></ProgressBar>
                        </div>
                        <div className="dsp mb-3">
                          <div>Capacity</div>
                          <div>
                            {fnum(Number(tvlCap))} {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp backGrd mb-3">
                          <div className="fnt_wgt_600 text-center">
                            <img
                              src={deatils?.assetImg}
                              className="wdth_30_px"
                              alt="usdt"
                            />{" "}
                            <br /> {deatils?.assetName}
                          </div>
                          <div>
                            <input
                              type="number"
                              id="first_name_2"
                              name="first_name_2"
                              value={withdrawAmout}
                              onChange={handlewithdrawAmoutChange}
                            />
                            <button
                              className="btn mxBtn"
                              onClick={updateWithdrawMax}
                            >
                              Max
                            </button>
                          </div>
                        </div>
                        <div className="dsp">
                          <div>Wallet balance</div>
                          <div>
                            {Number(walletBalance).toFixed(2)}{" "}
                            {deatils?.assetName}
                          </div>
                        </div>
                        <div className="dsp">
                          <div className="buy_cake mt-1 mb-2">
                            {" "}
                            <a
                              target="_blank"
                              href={deatils?.buyToken}
                              className="clr_prpl"
                              rel="noreferrer"
                            >
                              Buy ${deatils?.assetName}
                            </a>
                          </div>
                        </div>
                        <hr />
                        <div className="dsp">
                          <div>
                            Performance Fee
                            <img
                              className="toolTipPerformance ml_5"
                              src={infoCircleImg}
                              data-pr-tooltip="Performance Fee"
                              alt="info"
                            />
                            <Tooltip
                              target=".toolTipPerformance"
                              mouseTrack
                              mouseTrackLeft={10}
                            />
                          </div>
                          <div>{valutJsonData?.fees[0].settings.value}</div>
                        </div>
                        <div className="dsp">
                          <div>
                            Exit Fee
                            <img
                              className="toolTipExit ml_5"
                              src={infoCircleImg}
                              data-pr-tooltip="Exit Fee"
                              alt="info"
                            />
                            <Tooltip
                              target=".toolTipExit"
                              mouseTrack
                              mouseTrackLeft={10}
                            />
                          </div>
                          <div>{valutJsonData?.fees[1].settings.value}</div>
                        </div>
                        <div className="mt-3 text-center">
                          {isConnected ? (
                            (chain as any)?.id !== Number(valutJsonData?.id) ? (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                onClick={() => {
                                  networkSwitchHandler(
                                    Number(valutJsonData?.id)
                                  );
                                }}
                              >
                                Switch to {valutJsonData?.chainname}
                              </button>
                            ) : (
                              <button
                                className="btn btn-riv-primary wdth_100"
                                disabled={isTransactionOnGoing}
                                onClick={withdraw}
                              >
                                Withdraw
                              </button>
                            )
                          ) : (
                            <>
                              <div className="wdth_100">
                                <ConnectBtn />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mt-3 text-center">
                          <div>
                            <img src={zapIMg} alt="dollar" />
                            <span>Zap out with ${deatils?.assetName}</span>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Dialog
        visible={approvalWaitingVisible}
        className="dialogWdth"
        onHide={() => setapprovalWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for Approval</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>
      <Dialog
        visible={approvalSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setapprovalSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Approval Successful</div>
          <div>
            Opening Metamask, please confirm transaction in your wallet.
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={openMetamaskVisible}
        className="dialogWdth"
        onHide={() => setOpenMetamaskVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">Opening Metamask</div>
          <div>Please confirm transaction in your wallet.</div>
        </div>
      </Dialog>

      <Dialog
        visible={depositWaitingVisible}
        className="dialogWdth"
        onHide={() => setdepositWaitingVisible(false)}
      >
        <div className="text-center">
          <img src={waitingImg} alt="chain" className="gif_wdth" />
          <div className="header_font_size">Waiting for {transactionType}</div>
          <div>Transaction is pending on blockchain.</div>
        </div>
      </Dialog>

      <Dialog
        visible={depositSuccessfullVisible}
        className="dialogWdth"
        onHide={() => setdepositSuccessfullVisible(false)}
      >
        <div className="text-center">
          <img src={successfullImg} alt="chain" className="gif_success_wdth" />
          <div className="header_font_size">{transactionType} Successful</div>
          {transactionType === "Withdraw" ? (
            <></>
          ) : (
            <div>Great! You are invested now.</div>
          )}
        </div>
      </Dialog>
    </>
  );
}
