//import { getDefaultWallets } from '@rainbow-me/rainbowkit'
import { Chain, getDefaultWallets } from "@rainbow-me/rainbowkit";
import { configureChains, createClient } from "wagmi";
import { arbitrum, bsc, mainnet, optimism, polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { alchemyProvider } from "wagmi/providers/alchemy";

const mantaChain: Chain = {
  id: 169,
  name: "Manta Pacific",
  network: "ETH",
  iconUrl: "../img/manta.png",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Manta Pacific",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://pacific-rpc.manta.network/http"],
    },
    public: {
      http: ["https://pacific-rpc.manta.network/http"],
    },
  },
  blockExplorers: {
    default: {
      name: "SnowTrace",
      url: "https://pacific-explorer.manta.network",
    },
  },
};

const mantleChain: Chain = {
  id: 5000,
  name: "Mantle Network",
  network: "MNT",
  iconUrl: "../img/mantle.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Mantle Network",
    symbol: "MNT",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.mantle.xyz"],
    },
    public: {
      http: ["https://rpc.mantle.xyz"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://explorer.mantle.xyz" },
  },
};

const mantleTestChain: Chain = {
  id: 5001,
  name: "Mantle Testnet",
  network: "MNT",
  iconUrl: "../img/mantle.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Mantle Testnet",
    symbol: "MNT",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.testnet.mantle.xyz"],
    },
    public: {
      http: ["https://rpc.testnet.mantle.xyz"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://explorer.testnet.mantle.xyz" },
  },
  testnet: true,
};

// Network Details
// Name : RiveraTestnet Mantle
// Rpc : http://34.235.148.86:8545/
// ChainId :5000

const mantleLocalChain: Chain = {
  id: 5000,
  name: "Rivera Testnet (Mantle)",
  network: "MNT",
  iconUrl: "../img/mantle.svg",
  nativeCurrency: {
    decimals: 18,
    name: "Rivera Testnet (Mantle)",
    symbol: "MNT",
  },
  rpcUrls: {
    default: {
      http: ["https://node.rivera.money/"],
    },
    public: {
      http: ["https://node.rivera.money/"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://explorer.mantle.xyz" },
  },
  testnet: true,
};

const blastChain: Chain = {
  id: 168587773,
  name: "Blast Sepolia",
  network: "ETH",
  iconUrl: "../img/blastLogo.svg",
  nativeCurrency: {
    decimals: 18,
    name: "Ethereum (ETH)",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://sepolia.blast.io"],
    },
    public: {
      http: ["https://sepolia.blast.io"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://testnet.blastscan.io/" },
  },
  testnet: true,
};

const bnbLocalChain: Chain = {
  id: 56,
  name: "Rivera Testnet (BNB)",
  network: "BNB",
  iconUrl: "../img/bnbLogo.svg",
  //iconBackground: '#fff',
  nativeCurrency: {
    decimals: 18,
    name: "Rivera Testnet (BNB)",
    symbol: "BNB",
  },
  rpcUrls: {
    default: {
      http: ["https://testnet.rivera.money:8546/"],
    },
    public: {
      http: ["https://testnet.rivera.money:8546/"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://bscscan.com/" },
  },
  testnet: true,
};

const stratoVM: Chain = {
  id: 93747,
  name: "StratoVM Sepolia ",
  network: "SVM",
  iconUrl: "../img/stratVM.png",
  nativeCurrency: {
    decimals: 18,
    name: "SVM",
    symbol: "SVM",
  },
  rpcUrls: {
    default: {
      http: ["https://rpc.stratovm.io"],
    },
    public: {
      http: ["https://rpc.stratovm.io"],
    },
  },
  blockExplorers: {
    default: { name: "SnowTrace", url: "https://explorer.stratovm.io" },
  },
  testnet: true,
};

const { chains, provider } = configureChains(
  [stratoVM, blastChain],
  [
    alchemyProvider({ apiKey: process.env.ALCHEMY_ID as string }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: "My wagmi + RainbowKit App",
  chains,
  projectId: "ee56c353983496c87480ff2ae841a933",
});

export const client = createClient({
  autoConnect: true,
  connectors,
  provider,
});

export { chains };
