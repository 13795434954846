import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './App.css';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { SelectButton } from 'primereact/selectbutton';
import { backendApiUrlBase } from './constants/global';

function VaultList() {

  //table start
  const [filters, setFilters] = useState<any>(null);
  const [globalFilterValue, setGlobalFilterValue] = useState('');

  //const [strategyType, setStrategyType] = useState({ name: 'All Strategies', code: 'all' });
  const cities = [
    { name: 'All Strategies', code: 'all' },
    { name: 'Steady Spread Strategy', code: 'steady' },
    { name: 'Stable Spread Strategy', code: 'stable' },
  ];

  const [vaultType, setVaultType] = useState('allVault');
  const [vaultOptions] = useState([
    { label: 'All Vault', value: 'allVault' },
    { label: 'Stable', value: 'stable' },
    { label: 'Volatile', value: 'volatile' }
  ]);
  //table end

  const data = [
    {
      id: '1000',
      code: 'f230fh0g3',
      name: 'Bamboo Watch',
      description: 'Product Description',
      image: 'bamboo-watch.jpg',
      price: 65,
      category: 'Accessories',
      quantity: 24,
      inventoryStatus: 'INSTOCK',
      rating: 5,
      strategyTypeVal: "steady",
      vaultType: "volatile",
      chain: 'mantle',
    },
    {
      id: '1001',
      code: 'nvklal433',
      name: 'Black Watch',
      description: 'Product Description',
      image: 'black-watch.jpg',
      price: 72,
      category: 'Accessories',
      quantity: 61,
      inventoryStatus: 'INSTOCK',
      rating: 4,
      strategyTypeVal: "steady",
      vaultType: "volatile",
      chain: 'base',
    },
    {
      id: '1002',
      code: 'zz21cz3c1',
      name: 'Blue Band',
      description: 'Product Description',
      image: 'blue-band.jpg',
      price: 79,
      category: 'Fitness',
      quantity: 2,
      inventoryStatus: 'LOWSTOCK',
      rating: 3,
      strategyTypeVal: "stable",
      vaultType: "stable",
      chain: 'manta',
    },
    {
      id: '1003',
      code: '244wgerg2',
      name: 'Blue T-Shirt',
      description: 'Product Description',
      image: 'blue-t-shirt.jpg',
      price: 29,
      category: 'Clothing',
      quantity: 25,
      inventoryStatus: 'INSTOCK',
      rating: 5,
      strategyTypeVal: "stable",
      vaultType: "stable",
      chain: 'mantle',
    }];


  let { chainName, exchangeName } = useParams();
  const [products, setProducts] = useState<any>([]);
  const [actualData, setActualData] = useState<any>([]);
  const navigate = useNavigate();




  useEffect(() => {

    (async () => {
      const data = await fetch(`${backendApiUrlBase}vaults/dex?dex=${exchangeName}`);
      const jsonData = await data.json();
      console.log("jsonDta", jsonData);
      setProducts(jsonData);
      setActualData(jsonData);
      initFilters();
    })();



    //allVaultListFilter(vaultType, strategyType, chainName);
  }, []);

  useEffect(() => {
    allVaultListFilter(vaultType, chainName);
  }, [chainName, actualData]);


  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  }


  const getFormated = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  }

  const vaultTypeFun = (input: any) => {
    console.log("e", input);
    setVaultType(input.value);
    allVaultListFilter(input.value, chainName);
  }

  // const strategyTypeFun = (input: any) => {
  //   setStrategyType(input.value)
  //   allVaultListFilter(vaultType, input.value, chainName);
  // }

  const allVaultListFilter = (vaultType: any, chainName: any) => {
    let filterProducts;
    if (vaultType !== 'allVault') {
      filterProducts = actualData.filter((element: any) => element.vaultType === vaultType && element.chain === chainName);
    } else {
      filterProducts = actualData.filter((element: any) => element.chain === chainName);
    } 
    // else if (vaultType === 'allVault') {
    //   filterProducts = actualData.filter((element: any) => element.chain === chainName);
    // } else {
    //   filterProducts = actualData.filter((element: any) => element.chain === chainName);
    // }
    setProducts(filterProducts);
  }

  const onRowSelect = (event: any) => {
    console.log("event", event);
    navigate('/discover/' + exchangeName + '/' + chainName + '/0x713C1300f82009162cC908dC9D82304A51F05A3E_169');
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    setGlobalFilterValue('');
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    //if(filters){
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
    //}

  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between">
        <div>
          <SelectButton value={vaultType} onChange={(e) => vaultTypeFun(e)} options={vaultOptions} />
        </div>
        <div>
          {/* <span className='mr_10'>
            <Dropdown value={strategyType} onChange={(e) => strategyTypeFun(e)} options={cities} optionLabel="name"
              placeholder="Select a Strategie" className="w-full md:w-14rem" />
          </span> */}
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
          </span>
        </div>

      </div>
    );
  };

  const representativeBodyTemplate = (rowData: any) => {
    return (
      <>
        <div className='d-flex align-items-start'>
          <div className='d-flex'><img src='../img/usdtV2.svg' alt='btc img' className='btc_asst_img_width' /><img src="../img/usdc.svg" alt='btc img' className='btc_img_width' /></div>
          <div>
            <div className='vault_header_font_size'>{rowData.name}</div>
            <div className='card_snd_clr fnt_14 notMobileDevice'>{rowData.dex}</div>
          </div>
        </div>
      </>
    );
  };

  const tvlBodyTemplate = (rowData: any) => {
    return (
      <>
       ${getFormated(rowData.tvlUSD)}
      </>
    );
  };

  const header = renderHeader();

  return (
    <>
      <DataTable value={products} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
        selectionMode="single" onRowSelect={onRowSelect} showGridlines filters={filters} globalFilterFields={['code', 'name', 'category', 'quantity']}
        header={header} emptyMessage="No record found." dataKey="id">
        <Column field="code" header="Vault" body={representativeBodyTemplate}></Column>
        <Column field="symbol" header="Vault Symbol"></Column>
        <Column field="tvlUSD" header="TVL" body={tvlBodyTemplate}></Column>
        <Column field="quantity" header="Total APR(24h)"></Column>
      </DataTable>
    </>
  );
}

export default VaultList;
