import { useEffect, useState } from 'react';
import './App.css';
import { Skeleton } from 'primereact/skeleton';
import { useAccount } from 'wagmi';
import ConnectBtn from './ConnectBtn';
import keyCircleImg from './assets/images/keyCircle2.svg';
import seetingCircleImg from './assets/images/seetingCircle2.svg';
import rocketImg from './assets/images/rocket.png';
import tvlIMg from './assets/images/tvlv2.svg';
import { backendApiUrlBase } from './constants/global.js'

function Discover() {

  const viewDetailsbuttonVal = "Provide Liquidity --->"
  let exchangeList = ['FusionX', 'AGNI', 'ApertureSwap', 'iZiSwap'];
  const [exchnageDetailList, setExchnageDetailList] = useState<any>([]);
  const { address, isConnected } = useAccount();
  const [isDataLoadng, setIsDataLoadng] = useState(false);
  const [portfolio, setPortfolio] = useState(""); //need to check logic
  const [totalVault, setTotalVault] = useState(0); //will get this valur from backend api
  const [totalTvlInUsd, setTotalTvlInUsd] = useState(""); //will get this valur from backend api



  useEffect(() => {
    getExchangeDetails();
  }, []);

  const getExchangeDetails = async () =>{
    // const data = await fetch(`${backendApiUrlBase}vaults/stats`);
    // const jsonData = await data.json();
    // console.log("jsonData", jsonData);
    setExchnageDetailList([
      {
          "_id": "655af7f7d3c7af2d630a9c81",
          "chain": "mantle",
          "dex": "FusionX",
          "totalVaults": 4,
          "tvlUSD": 1306.780749814397,
          "exchangeImage": "/img/dex/fusionxDex.png",
          "defaultChain": "mantle"
      },
      {
          "_id": "655af8cfd3c7af2d630a9c82",
          "chain": "mantle",
          "dex": "Agni",
          "totalVaults": 4,
          "tvlUSD": 843.3005692601334,
          "defaultChain": "mantle",
          "exchangeImage": "/img/dex/agniDex.png"
      },
      {
          "_id": "655af8fad3c7af2d630a9c83",
          "chain": "manta-pacific",
          "dex": "ApertureSwap",
          "totalVaults": 4,
          "tvlUSD": 150024.3150175868,
          "defaultChain": "mantle",
          "exchangeImage": "/img/dex/apertureDex.png"
      }
  ]);
  }

  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum;
  }

  const getFormatedData = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  }

  return (
    <>
      <div className='custom-container'>
        {/* <div className='outer_section_first pddng_15'>
          <div>Protocol Stats</div>
          <div className='d-flex mt-2'>
            <div className='mr_20'>Total Value Locked<br /><span>$73.83M</span></div>
            <div className='mr_20'>Total Fees Accrued<br /><span>$14.32M</span></div>
            <div className='mr_20'>Staking APY<br /><span>1.93%</span></div>
          </div>
        </div> */}
         {isConnected ?
            <>
              <div className='second_section outer_section_first'>
                <div className='dsp_prt_cont'>
                  <div className='homePort'>
                      <div className='prtfol_mrgn'>
                        <div className='first_sec_heder1'>Portfolio Holdings</div>
                        <div className='first_sec_heder2'>
                          {isDataLoadng ? <><Skeleton width="5rem" className="mb-2"></Skeleton></> : <>${portfolio}</>}
                        </div>
                      </div>
                    </div>
                  
                  <div className='homePort_30'>
                    <div className='tvl_back pddng_20'>
                      <div className='dsp fnt_wgt_600'>
                        <span className='op_60'>TVL</span>
                        <div className='lqdt_icon_wdth_key'><img src={keyCircleImg} alt='tvl' /></div>
                      </div>
                      <div className='holding_header_inner secondary_color redHatFont'> {isDataLoadng ? <><Skeleton width="5rem" className="mb-2"></Skeleton></> : <>${getFormatedData(totalTvlInUsd)}</>}</div>
                    </div>
                    <div className='dspl_between'>
                      <div className='tvl_back pddng_20 width_48'>
                        <div className='dsp fnt_wgt_600'>
                          <span className='op_60'>Vaults</span>
                          <div className='lqdt_icon_wdth_key'><img src={keyCircleImg} alt='tvl' /></div>
                        </div>
                        <div className='holding_header_inner redHatFont'>{totalVault}</div>
                      </div>
                      <div className='tvl_back pddng_20 width_48'>
                        <div className='dsp fnt_wgt_600'>
                          <span className='op_60'>Automations </span>
                          <div className='lqdt_icon_wdth_key'><img src={seetingCircleImg} alt='tvl' /></div>
                        </div>
                        <div className='holding_header_inner redHatFont'>234</div>
                      </div>
                    </div>
                  </div>
                  <div >
                    <img src={rocketImg} alt='rocket img' className='rocketImg' />
                  </div>
                </div>
              </div>

            </>
            :
            <div className='second_section outer_section_first'>
              <div className='dsp_cont_first_section'>
                <div className='wdth_40_first'>
                  <div className='holding_header_inner mb-2 redHatFont secondary_color'>Concentrated Liquidity, Simplified!</div>
                  <div className='mb-3'>Earn passive yield with active market-making strategies.</div>
                  <div className='mb_20'><ConnectBtn /></div>
                </div>
                <div className='wdth_30_first'>
                  <div className='tvl_back pddng_20'>
                    <div className='dsp fnt_wgt_600 '>
                      <span className='op_60'>TVL</span>
                      <div className='lqdt_icon_wdth'>
                        <img src={tvlIMg} alt='tvl' />
                      </div>
                    </div>
                    <div className='holding_header_inner secondary_color redHatFont'>{isDataLoadng ? <><Skeleton width="5rem" className="mb-2"></Skeleton></> : <>${getFormatedData(totalTvlInUsd)}</>}</div>
                  </div>
                  <div className='dspl_between'>
                    <div className='tvl_back pddng_20 width_48'>
                      <div className='dsp fnt_wgt_600'>
                        <span className='op_60'>Vaults</span>
                        <div className='lqdt_icon_wdth_key'><img src={keyCircleImg} alt='tvl' /></div></div>
                      <div className='holding_header_inner redHatFont'>{totalVault}</div>
                    </div>
                    <div className='tvl_back pddng_20 width_48'>
                      <div className='dsp fnt_wgt_600'>
                        <span className='op_60'>Automations </span>
                        <div className='lqdt_icon_wdth_key'><img src={seetingCircleImg} alt='tvl' /></div></div>
                      <div className='holding_header_inner redHatFont'>234</div>
                    </div>
                  </div>


                </div>
                <div >
                  <img src={rocketImg} alt='rocket img' className='rocketImg' />
                </div>
              </div>
            </div>
          }

        <div className='second_section row mt-5 mb-5'>
          <h4 className="valut_header redHatFont fnt_wgt_600">Browse Exchanges</h4>

           {exchnageDetailList.map((e: any, index: any) => {
              return <div className='card_wdth_50' key={index}>
                <div className='pddng_15 outer_section'>
                   <div className='dsp_start exchng_mrgn'>
                    <div>
                      <div className='mb-4 first_sec_heder1'>{e.dex}</div>
                      <div className='d-flex'>
                        <div className='mr_35'>TVL <br/> <span className='fnt_wgt_600 secondary_color'>${getFormatedData(e.tvlUSD)}</span></div>
                        <div>Total Pairs <br/> <span className='fnt_wgt_600'>{e.totalVaults}</span></div>
                      </div>
                    </div>
                    <div className='dex_img_margn'><img alt='dex img' src={e.exchangeImage} /></div>
                    </div>
                    <div className="dsp_around mb-2 mt-4">
                      <div className="wdth_100">
                        <a className="btn btn-riv-secondary view_btn_wdth" href={`#/discover/${e.dex}/mantle`}>
                          {viewDetailsbuttonVal}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
            })}

        </div>
      </div>
    </>
  );
}

export default Discover;
